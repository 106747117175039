import { TemForm } from 'src/app/components'
import { ConstructionCompanyFilter } from './types'
import { Yup } from 'src/utils'
import { DefaultValues } from 'react-hook-form'

export const ConstructionCompanyForm = TemForm<ConstructionCompanyFilter>()

export const validationSchema = Yup.object().shape({
    status: Yup.array()
})

export const defaultValues: DefaultValues<ConstructionCompanyFilter> = {
    status: []
}