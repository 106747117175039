import { Typography } from '@mui/material'
import { PropToElement } from './types'
import { formatCurrency } from 'src/utils'
import { AtomButton } from 'src/app/components'

export const propsToElements: PropToElement[] = [
    {
        icon: 'fi-rr-ruler-combined', 
        element: ({ area }) => (
            <Typography variant='mobile-body'>
                {area} m²
            </Typography>
        )
    },
    {
        icon: 'fi-rr-bed',
        element: ({ dormitories }) => (
            <Typography variant='mobile-body'>
                {dormitories}
            </Typography>
        )
    },
    {
        icon: 'fi-rr-toilet-paper-blank',
        element: ({ carSpaces }) => (
            <Typography variant='mobile-body'>
                {carSpaces}
            </Typography>
        )
    },
    {
        icon: 'fi-rr-car-garage',
        element: ({ bathrooms }) => (
            <Typography variant='mobile-body'>
                {bathrooms}
            </Typography>
        )
    },
    {
        icon: 'fi-rs-star',
        element: ({ highlight }) => (
            <Typography variant='mobile-body'>
                {highlight || '-'}
            </Typography>
        )
    },
    {
        icon: 'fi-rr-usd-circle',
        element: ({ price }) => (
            <div className='price'>
                <Typography variant='mobile-body' className='label'>
                    a partir de
                </Typography>
                <Typography variant='mobile-h6'>
                    {formatCurrency(price).replace('R$', '')}
                </Typography>
            </div>
        )
    },
    {
        icon: 'fi-rr-messages-dollar',
        element: ({ pricePerMeter }) => (
            <div className='price'>
                <Typography variant='mobile-body'>
                    {pricePerMeter ? formatCurrency(pricePerMeter) : '-'}
                </Typography>
                <Typography variant='mobile-body' className='label'>
                    por m²
                </Typography>
            </div>
        )
    },
    {
        icon: '',
        element: ({ id, realEstateId }, navigate) => (
            <AtomButton onClick={() => navigate(`/real-estate/${realEstateId}/building-plan/${id}`)}>
                Saiba mais
            </AtomButton>
        )
    },
]