import { Typography } from '@mui/material'
import { MouseEvent, useState } from 'react'
import { Faq } from 'src/types'
import './faq.scss'

type MolFaqProps = {
    faq: Partial<Faq>
}

export const MolFaq = ({ faq }: MolFaqProps) => {
    const [open, setOpen] = useState<boolean>(false)

    const clickHandler = (event: MouseEvent<any>) => {
        event.preventDefault()
        setOpen(curValue => !curValue)
    }

    return (
        <details className='mol-faq' onClick={clickHandler} open={open}>
            <summary>
                <Typography variant='mobile-body' {...open && { color: 'primary', fontWeight: 'bold' }}>
                    {faq.question}
                </Typography>
                <i className={`fi fi-bs-angle-${open ? 'up' : 'down'}`}></i>
            </summary>

            <Typography variant='mobile-body'>
                { faq.answer }
            </Typography>
        </details>
    )
}