import { useContext } from 'react'
import { SchemaContext } from '../contexts'

export const useSchema = () => {
    const context = useContext(SchemaContext)

    if (!context) {
        console.error('Contexto do schema com erro.')
        return
    }

    return context
}