import { useContext } from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import './scroll-menu-arrow.scss'

type AtomScrollMenuArrowProps = {
    orientation: 'left' | 'right'
    type?: 'white' | 'blue' | 'transparent'
}

export const AtomScrollMenuArrow = ({ 
    orientation,
    type = 'transparent'
}: AtomScrollMenuArrowProps) => {
    const { scrollPrev, scrollNext, useIsVisible } = useContext(VisibilityContext)
    const disabled = useIsVisible(orientation === 'left' ? 'first' : 'last', orientation === 'left')

    return (
        <button 
            onClick={() => orientation === 'left' ? scrollPrev() : scrollNext()} 
            disabled={disabled} 
            className={`atom-scroll-menu-arrow ${type}`}
        >
            <i className={`fi fi-rs-angle-${orientation}`}></i>
        </button>
    )
}