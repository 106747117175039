import { FC, PropsWithChildren } from 'react'
import { ApplyInputDetailsProps } from './types'
import { AtomLabel } from '../../atoms'
import './field-enhancements.scss'

export const MolFieldEnhancements: FC<PropsWithChildren<ApplyInputDetailsProps>> = ({
    label,
    children,
    fieldState,
    field,
    layout = 'normal',
    labelColor = 'text',
    id
}) => (
    <div id={id} className={`mol-field-enhancements ${layout}`}>
        { label && <AtomLabel fieldName={field.name} label={label} layout={layout} color={labelColor} /> }
        { children }
    </div>
)