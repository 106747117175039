import { DefaultValues } from 'react-hook-form'
import { Yup } from 'src/utils'
import { MiniSearchFormData } from './types'
import { TemForm } from '../../templates'

export const validationSchema = Yup.object().shape({
    findResult: Yup.mixed()
})

export const defaultValues: DefaultValues<MiniSearchFormData> = {
    findResult: undefined,
}

export const MiniSearchForm = TemForm<MiniSearchFormData>()