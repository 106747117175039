import { Faq } from 'src/types'

export const placeholder: Partial<Faq>[] = [
    {
        id: 1,
        question: 'O que é a Hub House da Hausle?',
        answer: 'Nulla diam quam, gravida vitae dictum eu, pellentesque eget est. Duis hendrerit molestie purus id consectetur. Aenean nec facilisis leo, nec aliquam magna. Aliquam pellentesque.'
    },
    {
        id: 2,
        question: 'Como faço para entrar em contato?',
        answer: 'Pellentesque nibh augue, pellentesque id tincidunt eget, aliquam vel purus. Duis sed lorem ultrices, egestas leo tincidunt, cursus dui. Sed.'
    },
    {
        id: 3,
        question: 'Quais são as formas de pagamento?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla fringilla magna vitae turpis tempus, sit amet efficitur arcu volutpat. Vivamus sollicitudin nisi.'
    },
    {
        id: 4,
        question: 'Eu posso agendar uma visita no imóvel?',
        answer: 'Sed et eros quis orci viverra rhoncus nec vitae nibh. Pellentesque efficitur urna non pellentesque eleifend.'
    }
]