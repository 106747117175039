import { useBreakpoints } from 'src/app/hooks'
import { Typography } from '@mui/material'
import { OrgMiniSeach } from 'src/app/components'
import { Carousel } from 'react-responsive-carousel'
import './styles.scss'

const IMAGES = [
    'https://photos.hubhouse.imb.br/migrations/Ac%C3%A1cia+Condom%C3%ADnio+Parque/02.jpg',
    'https://photos.hubhouse.imb.br/migrations/Brooklyn+House/FACHADA/CROP.png',
    'https://photos.hubhouse.imb.br/migrations/Ac%C3%A1cia+Condom%C3%ADnio+Parque/19.jpg',
    'https://photos.hubhouse.imb.br/migrations/Alpen+Vila+Rodrigues/FACHADA/CAM+DETALHE_FINAL.png',
]

export const HomeBanner = () => {
    const { appType } = useBreakpoints()

    return (
        <div className='home-banner'>
            <Carousel
                className='carousel'
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                showIndicators={true}
                infiniteLoop={true}
                autoPlay={true}
                interval={5000}
                renderIndicator={(clickHandler, isSelected) => (
                    <button onClick={clickHandler} className={`indicator ${isSelected ? 'filled' : ''}`} />
                )}
            >
                {IMAGES.map((image, index) => (
                    <div key={index} className='image-container'>
                        {appType === 'desktop' && <div className='overlay' />}
                        <img src={image} />
                    </div>
                ))}
            </Carousel>

            { appType === 'desktop' && (
                <div className='content box'>
                    <div className='home-banner-card'>
                        <Typography variant='desktop-h0' color='white'>
                            A melhor oportunidade <br /> para investir em imóveis <br /> na planta
                        </Typography>

                        <OrgMiniSeach
                            labelColor='white' 
                            submitType='onChange' 
                        />
                    </div>
                </div>
            )}
        </div>
    )
}