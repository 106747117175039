import { AdvancedMarker, InfoWindow, Pin, useAdvancedMarkerRef } from '@vis.gl/react-google-maps'
import { RealEstate } from 'src/types'
import { FC, useCallback, useMemo } from 'react'
import { AtomLogo } from '../../atoms'
import { useTheme } from '@mui/material'
import { MolRealEstateCard } from '../real-estate-card/real-estate-card'
import './map-pin.scss'

type MolMapPinProps = {
    realEstate: RealEstate
    infoWindowFlag?: boolean
    openInfoWindow?: () => void
    closeInfoWindow?: () => void
}

export const MolMapPin: FC<MolMapPinProps> = ({
    realEstate,
    infoWindowFlag,
    openInfoWindow,
    closeInfoWindow
}) => {
    const [markerRef, marker] = useAdvancedMarkerRef()
    const theme = useTheme()

    const position: google.maps.LatLngLiteral = useMemo(() => ({
        lat: realEstate.address.location?.coordinates[0] || 0,
        lng: realEstate.address.location?.coordinates[1] || 0,
    }), [realEstate])

    const onMarkerClick = useCallback((event: google.maps.MapMouseEvent) => {
        event.stop()
        event.domEvent.stopPropagation()
        if(openInfoWindow) openInfoWindow()
    }, [openInfoWindow])

    return (
        <>
            <AdvancedMarker
                ref={markerRef}
                position={position}
                title={realEstate.name}
                onClick={onMarkerClick}
            >
                <Pin 
                    background={theme.palette.primary.main} 
                    borderColor={theme.palette.primary.main} 
                    scale={1.4}
                >
                    <AtomLogo type='whiteShort' onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                    }} />
                </Pin>
            </AdvancedMarker>
            {infoWindowFlag && (
                <InfoWindow
                    anchor={marker}
                    onCloseClick={closeInfoWindow}
                    onClose={closeInfoWindow}
                    headerDisabled={true}
                    className='OISFJAOIFAJSFOISAJFASIOFJASOIFJ'
                >
                    <MolRealEstateCard data={realEstate} />
                </InfoWindow>
            )}
        </>
    )
}