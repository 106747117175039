import { createContext } from 'react'

type HeaderTitleContextType = {
    title: string | null
    updateTitle: (message: string) => void
}

export const HeaderTitleContext = createContext<HeaderTitleContextType>({
    title: null,
    updateTitle: (message: string) => {}
})