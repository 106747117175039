import { Typography } from '@mui/material'
import { AtomButton, AtomLogo } from '../../atoms'
import { MolFooterAddress, MolFooterCopyright, MolFooterLinks, MolFooterMenus, MolFooterSocials } from '../../molecules'
import './footer.scss'
import { useBreakpoints } from 'src/app/hooks'
import { useMemo } from 'react'

export const OrgFooter = () => {
    const { appType } = useBreakpoints()

    const title = useMemo(() => (
        <Typography className='title' variant={`${appType}-h3`} color='white'>
            Os melhores imóveis novos ou na planta você encontra no nosso Hub.
        </Typography>
    ), [])

    const button = useMemo(() => (
        <AtomButton variant='tertiary'>
            Conheça a Hub House
        </AtomButton>
    ), [])

    const mobileLayout = useMemo(() => (
        <>
            <AtomLogo type='white' />
            { title }
            <MolFooterMenus />
            { button }
            <MolFooterSocials />
            <hr />
            <MolFooterLinks />
            <div>
                <MolFooterAddress />
                <br />
                <MolFooterCopyright />
            </div>
        </>
    ), [title, button])

    const desktopLayout = useMemo(() => (
        <>
            <AtomLogo type='white' />
            { title }
            <div className='left-group'>
                { button }
                <MolFooterAddress />
            </div>
            <div className='right-group'>
                <MolFooterMenus />
                <MolFooterLinks />
            </div>
            <MolFooterCopyright />
            <div className='socials'>
                <MolFooterSocials />
            </div>
        </>
    ), [title, button])

    return (
        <footer id='org-footer'>
            <div className='container box'>
                { appType === 'mobile' ? mobileLayout : desktopLayout }
            </div>
        </footer>
    )
}