import { OrgMiniSeach } from '../../../mini-search/mini-search'
import { Typography } from '@mui/material'
import { AtomBottomSheet } from 'src/app/components/atoms'
import './mobile.scss'

type Props = {
    flag: boolean
    close: () => void
}

export const SearchMobile = ({
    flag,
    close,
}: Props) => {
    return (
        <AtomBottomSheet isOpen={flag} onClose={close}>
            <div className='mol-search-mobile'>
                <Typography variant='mobile-h3' color='primary'>
                    Buscar imóvel
                </Typography>

                <OrgMiniSeach submitType='button' onSubmit={close} />
            </div>
        </AtomBottomSheet>
    )
}