import { ThemeProvider } from '@mui/material'
import { Router } from './app/router'
import { customTheme } from './constants'
import { APIProvider } from '@vis.gl/react-google-maps'
import { env } from './utils'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-horizontal-scrolling-menu/dist/styles.css'
import 'chart.js/auto'
import './App.scss'

const App = () => (
    <ThemeProvider theme={customTheme}>
        <APIProvider apiKey={env.googleCloud.mapsApiKey} libraries={['marker']}>
            <Router />
        </APIProvider>
    </ThemeProvider>
)

export default App