import { DefaultValues } from 'react-hook-form'
import { Yup } from 'src/utils'
import { RealEstateFormData } from 'src/types'
import { TemForm } from '../../templates'

export const validationSchemas = Yup.object().shape({
    city: Yup.string(),
    neighborhood: Yup.string(),
    roomsQty: Yup.array(),
    status: Yup.array(),
    priceMin: Yup.number()
        .transform((value) => Number.isNaN(value) ? undefined : value),
    priceMax: Yup.number()
        .transform((value) => Number.isNaN(value) ? undefined : value)
        .moreThan(Yup.ref('priceMin'), 'Deve ser maior que o preço mínimo'),
})

export const defaultValues: DefaultValues<RealEstateFormData> = {
    city: '',
    neighborhood: '',
    priceMax: undefined,
    priceMin: undefined,
    roomsQty: [],
    status: []
}

export const RealEstateForm = TemForm<RealEstateFormData>()