import { isArray } from 'lodash'
import { useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { getSearchParamsForLocation } from 'react-router-dom/dist/dom'

const isNumber = (value: string) => !isNaN(parseFloat(value))

type OriginalObject = {
    [key: string]: number | string | string[] | number[]
}

function objectToParams(originalObject: OriginalObject) {
    const queryParams = new URLSearchParams()

    Object.entries(originalObject).forEach(([key, value]) => {
        const isValueAnArray = isArray(value)

        if(!isValueAnArray && value) {
            queryParams.append(key, value.toString())
        }

        if (isValueAnArray && value && value.length > 0) {
            queryParams.append(key, `[${value.join(',')}]`)
        }
    })

    return queryParams.toString()
}

function paramsToObject<T>(params: URLSearchParams) {
    const result: any = {}

    for(const [key, value] of params.entries()) {
        let formattedValue: string | string[] | number

        if(isNumber(value)) {
            formattedValue = parseFloat(value)
        } else if(value.includes('[') && value.includes(']')) {
            formattedValue = value.replace(/\[|\]/g, '').split(',')
        } else {
            formattedValue = value
        }

        result[key] = formattedValue        
    }

    return result as T
}

export function useQuery<T>() {
    const { search } = useLocation()

    return useMemo(() => {
        const params = new URLSearchParams(search)

        return {
            query: search,
            queryObj: paramsToObject<T>(params),
            objectToParams
        }
    }, [search])
}