import { FC, useCallback, useState } from 'react'
import { useBreakpoints } from 'src/app/hooks'
import { AtomBottomSheet, AtomButton, AtomModal } from '../../atoms'
import { RealEstateEngageForm } from '../../molecules/real-estate-engage-form/types'
import { MolRealEstateEngageForm, MolRealEstateEngageResult } from '../../molecules'
import { ButtonVariant } from '../../atoms/button/types'

type OrgRealEstateEngageProps = {
    btnVariant?: ButtonVariant
    onClick?: () => void
}

export const OrgRealEstateEngage: FC<OrgRealEstateEngageProps> = ({
    btnVariant,
    onClick
}) => {
    const { appType } = useBreakpoints()
    const [content, setContent] = useState<'form' | 'error' | 'success' | null>(null)
    const [lastSubmitValue, setLastSubmitValue] = useState<RealEstateEngageForm | null>(null)

    const openContent = useCallback(() => {
        setContent('form')
        if(onClick) onClick()
    }, [setContent])

    const closeContent = useCallback(() => (
        setContent(null)
    ), [setContent])

    const onSubmit = useCallback((value: RealEstateEngageForm) => {
        setLastSubmitValue(value)
        setContent('success')
    }, [setLastSubmitValue, setContent])

    const onRetry = useCallback(() => (
        lastSubmitValue && onSubmit(lastSubmitValue)
    ), [onSubmit, lastSubmitValue])

    return (
        <>
            <AtomButton onClick={openContent} variant={btnVariant}>
                Saiba mais
            </AtomButton>
            
            {appType === 'mobile' && (
                <AtomBottomSheet isOpen={Boolean(content)} onClose={closeContent}>
                    {content === 'form' && <MolRealEstateEngageForm onSubmit={onSubmit} />}
                    {(content === 'success' || content === 'error') && (
                        <MolRealEstateEngageResult 
                            type={content}
                            onClose={closeContent}
                            onRetry={onRetry}
                        />
                    )}
                </AtomBottomSheet>
            )}

            {appType === 'desktop' && (
                <AtomModal flag={Boolean(content)} onClose={closeContent}>
                    {content === 'form' && <MolRealEstateEngageForm onSubmit={onSubmit} />}
                    {(content === 'success' || content === 'error') && (
                        <MolRealEstateEngageResult 
                            type={content}
                            onClose={closeContent}
                            onRetry={onRetry}
                        />
                    )}
                </AtomModal>
            )}
        </>
    )
}