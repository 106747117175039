import { Outlet } from 'react-router-dom'
import { OrgFooter, OrgHeader, OrgSearch } from '../../organisms'
import { MolSnackbar } from '../../molecules'
import { FaqsProvider, HeaderTitleProvider } from 'src/app/providers'
import { AtomScrollToTop } from '../../atoms'
import './base-layout.scss'

export const TemBaseLayout = () => {
    return (
        <HeaderTitleProvider>
            <AtomScrollToTop />
            <div className='base-layout'>
                <MolSnackbar />
                <OrgSearch />
                <OrgHeader/>
                <FaqsProvider>
                    <main>
                        <Outlet/>
                        <OrgFooter/>
                    </main>
                </FaqsProvider>
            </div>
        </HeaderTitleProvider>
    )
}