import { Typography } from '@mui/material'
import { useMemo } from 'react'
import { useBreakpoints } from 'src/app/hooks'
import { RealEstate } from 'src/types'
import { formatCurrency } from 'src/utils'
import { AtomUnderlinedText } from '../underlined-text/underlined-text'
import './price.scss'

type PriceType = 'starting_at' | 'buy_it_for'

type Props = {
    format?: PriceType
    align?: 'center' | 'left'
    valueColor?: 'primary' | 'text'
    showUnderline?: boolean
    valueVariant?: any
} & Pick<RealEstate, 'currentValue' | 'startingValue'>

export const AtomPrice = ({ 
    format = 'starting_at',
    align = 'left', 
    valueColor = 'primary',
    startingValue, 
    currentValue,
    showUnderline = false,
    valueVariant
}: Props) => {
    const { appType } = useBreakpoints()

    const formattedStartingValue = useMemo(() => (
        formatCurrency(startingValue).split(',')
    ), [startingValue])

    const minFormattedStartingvalue = useMemo(() => (
        formattedStartingValue.join().substring(2)
    ), [formattedStartingValue[0]])

    const formattedCurrentValue = useMemo(() => (
        currentValue ? formatCurrency(currentValue).split(',') : formattedStartingValue
    ), [currentValue])

    return (
        <div className='atom-price'>
            <Typography variant={`${appType}-body`} textAlign={align}>
                { format === 'starting_at' && 'A partir de' }
                { format === 'buy_it_for' && !currentValue && 'Compre por'}
                { format === 'buy_it_for' && currentValue && (
                    <>
                        {'De '}
                        <Typography variant={`${appType}-body`} color='primary' className='old-price'>
                            {minFormattedStartingvalue}
                        </Typography>
                        {' por'}
                    </>
                )}
            </Typography>

            <AtomUnderlinedText showUnderline={showUnderline} type='primary'>
                <Typography variant={valueVariant || `${appType}-button`} color={valueColor} textAlign={align}>
                    { formattedCurrentValue[0] } 
                    <Typography variant={`${appType}-miniText`} color={valueColor} fontWeight='bold'>
                        ,{ formattedCurrentValue[1] }
                    </Typography>
                </Typography>
            </AtomUnderlinedText>
        </div>
    )
}