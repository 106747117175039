import { get } from 'lodash'
import { FC, ReactElement, useMemo } from 'react'
import { useSchema } from 'src/app/hooks'
import './label.scss'

type AtomLabelProps = {
    fieldName?: string
    label: ReactElement | string | number
    layout?: 'normal' | 'mini'
    color?: 'text' | 'white'
}

export const AtomLabel: FC<AtomLabelProps> = ({ 
    fieldName,
    label,
    layout = 'normal',
    color = 'text'
}) => {
    const fieldDescription = useSchema()?.fields[fieldName || '']

    const isRequired = useMemo(
        () => !get(fieldDescription, 'optional'),
        [fieldDescription]
    )

    return (
        <label htmlFor={fieldName} className={`atom-label ${layout} ${color}`}>
            { label }
            { isRequired && <span className='required'> *</span> }
        </label>
    )
}