import { Typography } from '@mui/material'
import { placeholder } from './utils'
import { AtomDivider } from '../../atoms'
import { MolFaq } from '../../molecules'
import { useFaqs } from 'src/app/hooks'
import './faq.scss'

type OrgFaqProps = {
    showSubtitle?: boolean
}

export const OrgFaq = ({ showSubtitle }: OrgFaqProps) => {
    const { faqs } = useFaqs()

    const data = faqs || placeholder

    return (
        <div className='org-faq box'>
            <div className='title'>
                <Typography variant='mobile-h3'>
                    Perguntas frequentes
                </Typography>
                {showSubtitle && (
                    <Typography variant='mobile-body'>
                        Tire suas dúvidas
                    </Typography>
                )}
            </div>

            <div className='content'>
                {data.map((faq, index) => (
                    <>
                        <MolFaq faq={faq} />
                        {index < data.length -1 && <AtomDivider />}
                    </>
                ))}
            </div>
        </div>
    )
}