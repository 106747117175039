import { Album } from 'src/types'
import { Axios } from 'src/utils'


class AlbumService {
    private readonly endpoint = '/album'

    async listByRealEstateId(id: string) {
        const { data } = await Axios.get<Album[]>(
            `${this.endpoint}/by-real-estate/${id}`
        )
        return data
    }
}

export default new AlbumService()