import { FC, PropsWithChildren, useMemo, useState } from 'react'
import { useBreakpoints } from 'src/app/hooks'
import { AtomBottomSheet, AtomButton, AtomModal } from '../../atoms'
import './filter-button.scss'
import { Typography } from '@mui/material'

type AtomFilterButtonProps = {
    id?: string
    label: string
    badge?: number
    onContentToggle: () => void
    onSubmit: () => void
}

export const MolFilterButton: FC<PropsWithChildren<AtomFilterButtonProps>> = ({
    id,
    label,
    badge = 0,
    children,
    onContentToggle,
    onSubmit,
}) => {
    const [contentFlag, setContentFlag] = useState<boolean>(false)
    const { appType } = useBreakpoints()

    const openContent = () => {
        onContentToggle()
        setContentFlag(true)
    }

    const closeContent = () => {
        onContentToggle()
        setContentFlag(false)
    }

    const submitHandler = () => {
        onSubmit()
        setContentFlag(false)
    }

    const content = useMemo(() => (
        <div className='mol-filter-button-content'>
            { children }
            <AtomButton onClick={submitHandler}>
                Buscar
            </AtomButton>
        </div>
    ), [onSubmit, children])

    return (
        <>
            <button id={id} className='mol-filter-button' onClick={openContent}>
                <Typography variant={`${appType}-tags`} className='label'>
                    { label }
                </Typography>

                {badge >= 1 && (
                    <Typography variant={`${appType}-tags`} className='badge'>
                        { badge }
                    </Typography>
                )}

                <i className='fi fi-bs-angle-down'></i>
            </button>

            { appType === 'mobile' && (
                <AtomBottomSheet isOpen={contentFlag} onClose={closeContent}>
                    { content }
                </AtomBottomSheet>
            )}

            { appType === 'desktop' && (
                <AtomModal 
                    padding='small' 
                    width='23.4375rem' 
                    flag={contentFlag} 
                    onClose={closeContent}
                >
                    { content }
                </AtomModal>
            )}
        </>
    )
}