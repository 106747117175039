import { IconButton, Typography } from '@mui/material'
import { RealEstateComponentProps } from '../types'
import { AtomAvatar, MolCharacteristic, MolRealEstateAddress } from 'src/app/components'
import './head.scss'

export const RealEstateHead = ({ data }: RealEstateComponentProps) => {

    const {
        urls,
        constructionCompany,
        name,
        areaMin,
        areaMax,
        dormitoriesMin,
        dormitoriesMax,
        bathroomsMin,
        bathroomsMax,
        carSpacesMin,
        carSpacesMax
    } = data

    return (
        <div className='real-estate-head'>
            <div className='banner'>
                <img src={urls[0]} className='background-img' />
                <div className='buttons box'>
                    <IconButton className='share'>
                        <i className='fi fi-sr-share'></i>
                    </IconButton>
                </div>
            </div>
            <div className='data box'>
                <AtomAvatar 
                    size='extra-large' 
                    src={constructionCompany?.profilePictureUrl}
                />
                <div className='info'>
                    <Typography variant='desktop-h1'>
                        {name}
                    </Typography>
                    <Typography variant='desktop-body'>
                        {constructionCompany?.name}
                    </Typography>
                    <MolRealEstateAddress data={data} />
                </div>
                <MolCharacteristic
                    icon='fi-rr-ruler-combined'
                    min={areaMin}
                    max={areaMax}
                    postFix='Privativos'
                    valuePostFix='m²'
                />
                <MolCharacteristic
                    icon='fi-rr-bed'
                    min={dormitoriesMin}
                    max={dormitoriesMax}
                    postFix='Dormitorios'
                />
                <MolCharacteristic
                    icon='fi-rr-toilet-paper-blank'
                    min={bathroomsMin}
                    max={bathroomsMax}
                    postFix='Banheiros'
                />
                <MolCharacteristic
                    icon='fi-rr-car-garage'
                    min={carSpacesMin}
                    max={carSpacesMax}
                    postFix='Vagas'
                />
            </div>
        </div>
    )
}