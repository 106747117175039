import { Typography } from '@mui/material'
import { HomeSearch } from './search'
import { HomeList } from './list'
import { AtomDivider, OrgBestOpportunities, OrgFaq } from 'src/app/components'
import { HomeJoinUs } from './join-us'
import { HomeBanner } from './banner'
import { useBreakpoints } from 'src/app/hooks'
import './styles.scss'

export const Home = () => {
    const { appType } = useBreakpoints()

    return (
        <div id='home'>
            <HomeBanner />
            { appType === 'mobile' && (
                <>
                    <Typography className='box' variant='mobile-h2' color='primary'>
                        Os melhores <br /> lançamentos imobiliários <br /> em um só lugar
                    </Typography>
                    <HomeSearch />
                    <AtomDivider />
                </>
            )}
            <HomeList />
            <AtomDivider />
            {appType === 'mobile' && (
                <>
                    <HomeJoinUs />
                    <OrgBestOpportunities />
                    <AtomDivider />
                    <OrgFaq />
                </>
            )}
            {appType === 'desktop' && (
                <>
                    <OrgBestOpportunities desktopBgColor='white' />
                    <HomeJoinUs />
                </>
            )}
        </div>
    )
}