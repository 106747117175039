import { Typography } from '@mui/material'
import { FC } from 'react'
import { useBreakpoints } from 'src/app/hooks'
import { AtomButton } from '../../atoms'
import './real-estate-engage-result.scss'

type MolRealEstateEngageResultProps = {
    type: 'success' | 'error'
    onClose: () => void
    onRetry: () => void
}

export const MolRealEstateEngageResult: FC<MolRealEstateEngageResultProps> = ({
    type,
    onClose,
    onRetry
}) => {
    const { appType } = useBreakpoints()

    return (
        <div className='mol-real-estate-engage-result'>
            <Typography variant={`${appType}-h3`} className={`title ${type}`}>
                { type === 'success' ? 'Sucesso!' : 'Tivemos um problema' }
            </Typography>
            <Typography variant={`${appType}-body`}>
                { type === 'success' 
                    ? 'Recebemos seus dados e entraremos em contato o mais breve possível.' 
                    : 'Não conseguimos salvar sua solicitação nesse momento. Tente novamente mais tarde.' 
                }
            </Typography>
            <AtomButton 
                variant={type === 'success' ? 'primary' : 'contained'}
                onClick={type === 'success' ? onClose : onRetry}
            >
                { type === 'success' ? 'Fechar' : 'Tentar novamente' }
            </AtomButton>
        </div>
    )
}