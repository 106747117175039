import { FC, useCallback, useEffect } from 'react'
import { MiniSearchFormData, OrgMiniSearchProps } from './types'
import { useForm, useQuery } from 'src/app/hooks'
import { defaultValues, MiniSearchForm, validationSchema } from './form-controller'
import { useNavigate } from 'react-router-dom'
import { AtomButton } from '../../atoms'
import { CoreService } from 'src/services'
import { Address, RealEstateFormData } from 'src/types'
import './mini-search.scss'

export const OrgMiniSeach: FC<OrgMiniSearchProps> = ({
    submitType, 
    onSubmit, 
    labelColor,
}) => {
    const navigate = useNavigate()
    const { objectToParams } = useQuery()

    const formObject = useForm<MiniSearchFormData>({
        defaultValues,
        validationSchema,
    })

    const findResult = formObject.form.watch('findResult')

    const searchCallback = useCallback((value: string) => {
        if(value.length === 0) return

        return CoreService.find(value)
    }, [])

    const submitHandler = () => {
        const formValue = formObject.form.getValues()
        const { findResult } = formValue

        if(onSubmit) onSubmit(formValue)

        if(!findResult) return

        if(findResult.group === 'RealEstate.name') {
            navigate(`/real-estate/${findResult.value.id}`)
            return
        }

        const realEstateFormData: RealEstateFormData = {
            [findResult.group]: (findResult.value as Address)[findResult.group],
            ...(findResult.group === 'neighborhood' && { 
                city: (findResult.value as Address).city 
            })
        }

        navigate({
            pathname: '/real-estate',
            search: objectToParams(realEstateFormData)
        })
    }

    useEffect(() => {
        if(submitType === 'onChange' && findResult) submitHandler()
    }, [submitType, findResult])

    return (
        <MiniSearchForm
            id='org-mini-search-form' 
            formObject={formObject}
        >
            <MiniSearchForm.Autocomplete
                id='findResult'
                name='findResult'
                label='Onde você gostaria de investir?'
                searchCallback={searchCallback}
                groupBy={option => option.groupLabel}
                getOptionLabel={option => option.label}
                labelColor={labelColor}
                showHelperMsg={false}
                placeholder='Bairro, cidade ou empreendimento'
                {...submitType !== 'button' && { endAdornment: 'fi-br-search' }}
            />

            { submitType === 'button' && (
                <AtomButton 
                    type='submit' 
                    onClick={() => formObject.form.handleSubmit(submitHandler)()}
                >
                    Buscar
                </AtomButton>
            )}
        </MiniSearchForm>
    )
}