import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'

export const MolFooterCopyright = () => {
    const { appType } = useBreakpoints()

    return (
        <div id='mol-footer-copyright'>
            <Typography
                variant={`${appType}-miniText`}
                color='white'
            >
                Todos os Direitos Reservados.
                <br />
                <Typography
                    variant={`${appType}-miniText`}
                    color='white'
                    fontWeight='bold'
                >
                    Hub House
                </Typography> by Hausle Marketing Imobiliário. 
                <br />
                CRECI-RS 080583
            </Typography>
        </div>
    )
}