import { Drawer } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import './bottom-sheet.scss'

type AtomBottomSheetProps = {
    isOpen: boolean
    onClose: () => void
    variant?: 'permanent' | 'persistent' | 'temporary'
    paddingType?: 'normal' | 'small'
}

export const AtomBottomSheet: FC<PropsWithChildren<AtomBottomSheetProps>> = ({
    isOpen,
    onClose,
    variant,
    paddingType = 'normal',
    children
}) => (
    <Drawer
        className='atom-bottom-sheet'
        anchor='bottom'
        open={isOpen}
        onClose={onClose}
        variant={variant}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
        PaperProps={{ className: paddingType }}
    >
        <div className='puller' onClick={onClose}></div>
        { children }
    </Drawer>
)