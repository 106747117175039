import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import { AtomButton, AtomPlaceholder } from '../../atoms'
import './get-info.scss'

type MolGetInfoProps = {
    btnWidth?: 'normal' | 'full'
}

export const MolGetInfo = ({
    btnWidth = 'normal'
}: MolGetInfoProps) => {

    const { appType } = useBreakpoints()

    return (
        <div className='mol-get-info box'>
            <AtomPlaceholder />

            <div className='description'>
                <Typography 
                    variant={`${appType}-h1`} 
                    {...appType === 'mobile' && { color: 'primary' }}
                >
                    Ainda não achou o que procurava?
                </Typography>

                <Typography variant={`${appType}-body`}>
                    Inscreva-se na nossa lista e esteja um passo a frente dos lançamentos na sua nossa cidade
                </Typography>

                <div {...btnWidth === 'full' && { className: 'full-width' }}>
                    <AtomButton>
                        Receber informações
                    </AtomButton>
                </div>
            </div>
        </div>
    )
}