import { Typography } from '@mui/material'
import { AtomAvatar, AtomTag } from 'src/app/components'
import { useBreakpoints } from 'src/app/hooks'
import { RevertState } from 'src/enums'
import { ConstructionCompany } from 'src/types'
import './banner.scss'
import { useMemo } from 'react'

type ConstructionCompanyBannerProps = {
    data: ConstructionCompany
}

export const ConstructionCompanyBanner = ({
    data: {
        bannerUrl,
        profilePictureUrl,
        name,
        summary,
        realEstatesAddresses,
        realEstatesQty
    }
}: ConstructionCompanyBannerProps) => {
    const { appType } = useBreakpoints()

    const infoComponent = useMemo(() => (
        <div className="info">
            <Typography variant={appType === 'desktop' ? 'desktop-h1' : 'mobile-h3'} className='name'>
                { name }
            </Typography>
            <Typography variant={appType === 'desktop' ? 'desktop-body' : 'mobile-miniText'} className='summary'>
                { summary }
            </Typography>
        </div>
    ), [name, summary, appType])

    const addressesComponent = useMemo(() => (
        <div className='addresses'>
            {realEstatesAddresses?.map(address => (
                <AtomTag
                    key={address.id}
                    label={`${address.city}/${RevertState[address.state]}`}
                />
            ))}
        </div>
    ), [realEstatesAddresses])

    const countComponent = useMemo(() => (
        <div className='count'>
            <i className="fi fi-rs-house-building icon-size"></i>
            <Typography variant={appType === 'desktop' ? 'desktop-h3' : 'mobile-h5'}>
                {realEstatesQty || 0}
            </Typography>
            <Typography variant={`${appType}-miniText`}>
                empreend.
            </Typography>
        </div>
    ), [realEstatesQty, appType])

    return (
        <div className='construction-company-banner'>
            <div className='background'>
                <img src={bannerUrl} />
            </div>

            <div className="content box">
                {appType === 'desktop' && (
                    <>
                        <AtomAvatar
                            src={profilePictureUrl}
                            size='xx-large'
                        />
                        <div className='left'>
                            { infoComponent }
                            { addressesComponent }
                        </div>
                        <div className='right'>
                            { countComponent }
                        </div>
                    </>
                )}

                {appType === 'mobile' && (
                    <>
                        <div className='main'>
                            <AtomAvatar
                                src={profilePictureUrl}
                                size='large'
                            />
                            { infoComponent }
                        </div>
                        <div className='other'>
                            { addressesComponent }
                            { countComponent }
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}