import { Card, CardContent, Typography } from '@mui/material'
import { Carousel } from 'react-responsive-carousel'
import { RealEstate } from 'src/types'
import { AtomCarousel, AtomPrice, AtomTag } from '../../atoms'
import { useBreakpoints } from 'src/app/hooks'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { RevertState } from 'src/enums'
import './real-estate-card.scss'

type RealEstateCardProps = {
    data: RealEstate
}

export const MolRealEstateCard = ({ data }: RealEstateCardProps) => {
    const { appType } = useBreakpoints()
    const navigate = useNavigate()

    const { 
        id,
        name,
        urls,
        address: {
            city,
            state,
            neighborhood,
        },
        currentValue, 
        startingValue,
        areaMin,
        areaMax,
        carSpacesMin,
        carSpacesMax,
        dormitoriesMin,
        dormitoriesMax
    } = data

    const navigateHandler = useCallback(() => navigate(`/real-estate/${id}`), [id])

    return (
        <Card className='real-estate-card' onClick={navigateHandler}>
            <AtomCarousel>
                {urls && urls.map(url => (
                    <img key={url} src={url} className='real-estate-img' />
                ))}
            </AtomCarousel>

            <CardContent>
                <div className='tags'>
                    {city && state && (
                        <AtomTag label={`${city}/${RevertState[state]}`} />
                    )}
                    {neighborhood && (
                        <AtomTag label={neighborhood} />
                    )}
                </div>

                <Typography variant={`${appType}-h3`}>
                    {name}
                </Typography>

                <div className='infos'>
                    <AtomPrice 
                        startingValue={startingValue} 
                        currentValue={currentValue} 
                        format='starting_at'
                        showUnderline={false}
                    />

                    <div className='characteristics'>
                        <div className='characteristic'>
                            <i className='fi fi-rr-bed'></i>
                            <Typography variant={`${appType}-caracAptos`}>
                                {dormitoriesMin} {dormitoriesMax && `e ${dormitoriesMax}`}
                            </Typography>
                        </div>

                        <div className='characteristic'>
                            <i className='fi fi-rr-ruler-combined'></i>
                            <Typography variant={`${appType}-caracAptos`}>
                                {areaMin} {areaMax && `a ${areaMax}`}m²
                            </Typography>
                        </div>

                        <div className='characteristic'>
                            <i className='fi fi-rr-car-garage'></i>
                            <Typography variant={`${appType}-caracAptos`}>
                                {carSpacesMin} {carSpacesMax && `e ${carSpacesMax}`}
                            </Typography>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}