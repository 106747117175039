import { DefaultValues } from 'react-hook-form'
import { Yup } from 'src/utils'
import { RealEstateEngageForm } from './types'
import { TemForm } from '../../templates'

export const validationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    number: Yup.string().required()
})

export const defaultValues: DefaultValues<RealEstateEngageForm> = {
    name: undefined,
    email: undefined,
    number: undefined
}

export const Form = TemForm<RealEstateEngageForm>()