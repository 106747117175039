import { createContext } from 'react'
import { Faq } from 'src/types'

type FaqsContextType = {
    faqs: Faq[] | undefined
    loading: boolean
    error: unknown
}

export const FaqsContext = createContext<FaqsContextType>({
    faqs: undefined,
    loading: false,
    error: undefined,
})