import { AtomModal, AtomPlaceholder } from 'src/app/components/atoms'
import { OrgRealEstateForm } from '../../../real-estate-form/real-estate-form'
import './desktop.scss'

type Props = {
    flag: boolean
    close: () => void
}

export const SearchDesktop = ({
    flag,
    close,
}: Props) => {
    return (
        <AtomModal flag={flag} onClose={close} className='search-form-desktop'>
            <AtomPlaceholder />

            <OrgRealEstateForm onSubmit={close} />
        </AtomModal>
    )
}