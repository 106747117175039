import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import './characteristic.scss'

type MolCharacteristicProps = {
    icon: string
    min: number
    max?: number
    postFix: string
    valuePostFix?: string
}

export const MolCharacteristic = ({
    icon,
    min,
    max,
    postFix,
    valuePostFix
}: MolCharacteristicProps) => {
    const { appType } = useBreakpoints()

    return (
        <div className='mol-characteristic'>
            <i className={`fi ${icon}`}></i>
            <div className='content'>
                <Typography variant={`${appType}-h5`} fontWeight='400'>
                    <Typography variant={`${appType}-h5`}>
                        {min}{!max && (valuePostFix || '')}
                    </Typography>
                    {Boolean(max) && (
                        <>
                            {' a '}
                            <Typography variant={`${appType}-h5`}>
                                {max}{valuePostFix || ''}
                            </Typography>
                        </>
                    )}
                </Typography>
                <Typography variant={`${appType}-tags`} align='center'>
                    {postFix}
                </Typography>
            </div>
        </div>
    )
}