import { yupResolver } from '@hookform/resolvers/yup'
import { useMemo } from 'react'
import {
    FieldValues,
    Resolver,
    useForm as useOriginalForm,
    UseFormProps as useOriginalFormProps,
} from 'react-hook-form'
import { Yup } from 'src/utils'

export type SchemaOf<T extends AnyRecord> = Yup.ObjectSchema<DeepPartial<T>>

export const useForm = <TFieldValues extends FieldValues, TContext = any>({
    validationSchema,
    context,
    ...propsRest
}: UseFormProps<TFieldValues, TContext>) => {
    const resolver = useMemo(
        () =>
        yupResolver(validationSchema) as unknown as Resolver<TFieldValues, TContext>,
        [validationSchema],
    )
    const form = useOriginalForm({
        ...propsRest,
        resolver,
        mode: 'onChange',
        context,
    })

    return { form, context, schema: validationSchema }
}

type UseFormProps<TFieldValues extends FieldValues, TContext = any> = Omit<
  useOriginalFormProps<TFieldValues, TContext>,
  'resolver'
> & { validationSchema: SchemaOf<AnyObject> }
