import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import './header-menus.scss'

export const MolHeaderMenus = () => {

    const navigate = useNavigate()

    const goTo = (url: string) => navigate(url)

    return (
        <>
            <Button className='mol-header-menu' onClick={() => goTo('/real-estate')}>
                Empreendimentos
            </Button>

            <Button className='mol-header-menu' onClick={() => goTo('/construction-company')}>
                Construtoras
            </Button>

            <Button className='mol-header-menu'>
                Sobre
            </Button>

            <Button className='mol-header-menu' onClick={() => goTo('/help')}>
                Dúvidas
            </Button>
        </>
    )
}