import { ConstructionCompany, RealEstate } from 'src/types'
import { FC } from 'react'
import { RealEstateHead } from '../components'
import { 
    AtomDivider, 
    MolGetInfo, 
    OrgRealEstateBuildingPlans, 
    MolRealEstateFeatures, 
    OrgRealEstateStatuses, 
    OrgBuildingPlansTable,
    OrgConstructionCompanyInfo,
    OrgConstructionCompanyOthers,
    OrgRealEstateMiniGallery,
    OrgRealEstateVideos,
    MolRealEstateMap,
    MolRealEstateDescription,
    MolRealEstatePayment,
    MolRealEstatePriceTable
} from 'src/app/components'
import { FeatureType } from 'src/enums'
import { ComponentFlags } from '../types'
import './desktop.scss'

type RealEstateDesktopProps = {
    data: {
        realEstate: RealEstate
        constructionCompany: ConstructionCompany
    }
    flags: ComponentFlags
}

export const RealEstateDesktop: FC<RealEstateDesktopProps> = ({ 
    data: {
        realEstate,
        constructionCompany
    }, 
    flags 
}) => (
    <div className='real-estate-page-desktop'>
        <RealEstateHead data={realEstate} />
        <AtomDivider className='box' />
        <div className='grid box'>
            <div className='left-container'>
                <MolRealEstateDescription data={realEstate} />
                <AtomDivider />
                <OrgRealEstateStatuses data={realEstate} className='box align-center' />
                {flags.videos && <AtomDivider />}
                {flags.videos && <OrgRealEstateVideos data={realEstate} />}
                <AtomDivider />
                <MolRealEstateFeatures data={realEstate} type={FeatureType.about} />
                <AtomDivider />
                <MolRealEstateFeatures data={realEstate} type={FeatureType.structure} />
            </div>
            <div className='offset' />
            <div className='right-container'>
                <MolRealEstatePayment className='payment-card' data={realEstate} />
            </div>
        </div>
        {flags.albums && <AtomDivider className='box' />}
        {flags.albums && <OrgRealEstateMiniGallery data={realEstate} />}
        {flags.buildingPlans && <OrgRealEstateBuildingPlans data={realEstate} />}
        {flags.buildingPlansTable && <OrgBuildingPlansTable data={realEstate} />}
        <MolRealEstatePriceTable data={realEstate} />
        {flags.map && <MolRealEstateMap data={realEstate} />}
        {flags.constructionCompany && <AtomDivider className='box' />}
        {flags.constructionCompany && <OrgConstructionCompanyInfo data={constructionCompany} contentClassName='box'/>}
        {flags.constructionCompany && <OrgConstructionCompanyOthers data={realEstate} />}
        <MolGetInfo />
    </div>
)