import { RealEstateComponentProps } from '../types'
import { MolCharacteristic } from 'src/app/components'
import './characteristics.scss'

type RealEstateCharacteristicsProps = {
    extraClassName?: string
} & RealEstateComponentProps

export const RealEstateCharacteristics = ({
    data: {
        carSpacesMin,
        carSpacesMax,
        dormitoriesMin,
        dormitoriesMax,
        areaMin,
        areaMax,
        bathroomsMin,
        bathroomsMax
    },
    extraClassName
}: RealEstateCharacteristicsProps) => (
    <div className={`real-estate-characteristics ${extraClassName || ''}`}>
        <MolCharacteristic
            icon='fi-rr-ruler-combined'
            min={areaMin}
            max={areaMax}
            postFix='Privativos'
            valuePostFix='m²'
        />
        <MolCharacteristic
            icon='fi-rr-bed'
            min={dormitoriesMin}
            max={dormitoriesMax}
            postFix='Dormitorios'
        />
        <MolCharacteristic
            icon='fi-rr-toilet-paper-blank'
            min={bathroomsMin}
            max={bathroomsMax}
            postFix='Banheiros'
        />
        <MolCharacteristic
            icon='fi-rr-car-garage'
            min={carSpacesMin}
            max={carSpacesMax}
            postFix='Vagas'
        />
    </div>
)