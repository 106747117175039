import { FC } from 'react'
import { ConstructionCompany, RealEstate } from 'src/types'
import { 
    RealEstateCarousel,
    RealEstateCharacteristics,
} from '../components'
import { FeatureType } from 'src/enums'
import { 
    AtomDivider, 
    MolGetInfo, 
    OrgRealEstateBuildingPlans, 
    MolRealEstateDescription, 
    MolRealEstateFeatures, 
    MolRealEstatePayment, 
    OrgRealEstateStatuses, 
    OrgBuildingPlansTable, 
    OrgConstructionCompanyInfo, 
    OrgConstructionCompanyOthers, 
    OrgFaq, 
    OrgRealEstateMiniGallery,
    OrgRealEstateVideos,
    MolRealEstateMap,
    MolRealEstateAddress,
    MolRealEstatePriceTable
} from 'src/app/components'
import { ComponentFlags } from '../types'
import { RealEstateBottomSheet } from '../components/bottom-sheet/bottom-sheet'
import './mobile.scss'

type RealEstateMobileProps = {
    data: {
        realEstate: RealEstate
        constructionCompany: ConstructionCompany
    }
    flags: ComponentFlags
}

export const RealEstateMobile: FC<RealEstateMobileProps> = ({
    data: {
        realEstate,
        constructionCompany
    },
    flags,
}) => (
    <div className='real-estate-page-mobile'>
        <RealEstateCarousel data={realEstate} />
        <MolRealEstateDescription data={realEstate} className='box' />
        <MolRealEstateAddress data={realEstate} extraClassName='box' />
        <RealEstateCharacteristics data={realEstate} extraClassName='box' />
        {flags.videos && <AtomDivider className='box' />}
        {flags.videos && <OrgRealEstateVideos data={realEstate} />}
        <OrgRealEstateStatuses data={realEstate} className='box' />
        <AtomDivider className='box' />
        <MolRealEstatePayment data={realEstate} className='box' />
        {flags.albums && <OrgRealEstateMiniGallery data={realEstate} />}
        {flags.albums && <AtomDivider className='box' />}
        <AtomDivider className='box' />
        <MolRealEstateFeatures data={realEstate} type={FeatureType.about} className='box' />
        <AtomDivider className='box' />
        <MolRealEstateFeatures data={realEstate} type={FeatureType.structure} className='box' />
        {flags.buildingPlans && <AtomDivider className='box' />}
        {flags.buildingPlans && <OrgRealEstateBuildingPlans data={realEstate} />}
        <MolRealEstatePriceTable data={realEstate} />
        {flags.buildingPlansTable && <AtomDivider className='box' />}
        {flags.buildingPlansTable && <OrgBuildingPlansTable data={realEstate} />}
        {flags.map && <MolRealEstateMap data={realEstate} />}
        {realEstate.constructionCompany && <AtomDivider className='box' />}
        {realEstate.constructionCompany && <OrgConstructionCompanyInfo data={constructionCompany} contentClassName='box' />}
        {realEstate.constructionCompany && <OrgConstructionCompanyOthers data={realEstate} />}
        <MolGetInfo btnWidth='full' />
        <AtomDivider className='box' />
        <OrgFaq showSubtitle={false} />
        <RealEstateBottomSheet data={realEstate} />
    </div>
)
