import { MouseEvent, ReactElement, startTransition, useCallback, useEffect, useRef, useState } from 'react'
import { Carousel, CarouselProps as OriginalCarouselProps } from 'react-responsive-carousel'
import './carousel.scss'

type CarouselProps = {
    children: ReactElement[]
    centerMode?: boolean
    centerSlidePercentage?: number
    arrowColor?: 'white' | 'grey'
    arrowPosition?: number
    startAt?: number
    onIndexChange?: (index: number) => void
}

export const AtomCarousel = ({ 
    children, 
    arrowColor = 'white',
    startAt = 0,
    arrowPosition = 0,
    onIndexChange,
    ...props 
}: CarouselProps) => {
    const carouselRef = useRef<Carousel>(null)
    const [selectedIndex, setSelectedIndex] = useState<number>(startAt)

    const onArrowClick = (event: MouseEvent<any>, handler: () => void) => {
        event.stopPropagation()
        handler()
    }

    const arrow = useCallback((type: 'right' | 'left', handler: () => void, hasNext: boolean) => (
        <button 
            className={`carousel-btn ${type}`} 
            onClick={(evt) => onArrowClick(evt, handler)} 
            disabled={!hasNext}
            style={{ [type]: `${arrowPosition}rem` }}
        >
            <i className={`fi fi-bs-angle-${type} ${arrowColor}`}></i>
        </button>
    ), [arrowPosition])

    useEffect(() => {
        if (onIndexChange) {
            onIndexChange(selectedIndex)
        }

      
    }, [selectedIndex, onIndexChange])


    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.moveTo(startAt)
        }
    }, [startAt])

    return (
        <Carousel
            ref={carouselRef}
            selectedItem={selectedIndex}
            onChange={(newIndex) => setSelectedIndex(newIndex)}
            {...props}
            showStatus={false} 
            showIndicators={false} 
            showThumbs={false}
            renderArrowNext={(handler, hasNext) => arrow('right', handler, hasNext)}
            renderArrowPrev={(handler, hasNext) => arrow('left', handler, hasNext)}
        >
            { children }
        </Carousel>
    )
}