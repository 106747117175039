import { useBreakpoints } from 'src/app/hooks'
import { OrgSearchProps } from './types'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { SearchDesktop, SearchMobile } from './components'

const OrgSearch: OrgSearchProps = () => {
    const { appType } = useBreakpoints()

    const [flag, setFlag] = useState<boolean>(false)

    const open = () => setFlag(true)
    const close = () => setFlag(false)

    useEffect(() => {
        OrgSearch.close = close
        OrgSearch.open = open
    }, [])

    return (
        <>
            { createPortal(
                appType === 'mobile' 
                    ? <SearchMobile close={close} flag={flag} />
                    : <SearchDesktop close={close} flag={flag} />,
                document.body
            )}
        </>
    )
}

OrgSearch.open = () => {
    throw new Error('OrgSearch has not been initialized')
}

OrgSearch.close = () => {
    throw new Error('OrgSearch has not been initialized')
}

export default OrgSearch