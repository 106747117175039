import { RealEstateStatus } from 'src/enums'
import { ComponentFlags } from './types'

export const HIGHLIGHT_STATUS: RealEstateStatus[] = [
    RealEstateStatus.preLaunch,
    RealEstateStatus.launch, 
    RealEstateStatus.underConstruction,
    RealEstateStatus.readyToLive,
]

export const DEFAULT_COMPONENT_FLAGS: ComponentFlags = {
    videos: false,
    albums: false,
    buildingPlans: false,
    buildingPlansTable: false,
    map: false,
    constructionCompany: false,
}