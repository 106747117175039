import { InputAdornment, TextField } from '@mui/material'
import WithController from '../with-controller/with-controller'
import { noBreakSpaceUni } from 'src/utils'
import { MolFieldEnhancements } from '../field-enhancements/field-enhancements'
import { TextFieldProps } from './types'
import './text-field.scss'

export const MolTextField = WithController<TextFieldProps, string>(
    ({
        field,
        fieldState,
        formState: _formState,
        label,
        triggerDependencies,
        startAdornment = false,
        showHelperMsg = true,
        id,
        layout = 'normal',
        ...props
    }) => (
        <MolFieldEnhancements
            label={label}
            field={field}
            fieldState={fieldState}
            layout={layout}
            id={id}
        >
            <TextField
                {...props}
                className={`mol-text-field ${layout}`}
                disabled={field.disabled}
                onBlur={field.onBlur}
                onChange={(event) => {
                    field.onChange(event)
                    triggerDependencies()
                }}
                variant='outlined'
                ref={field.ref}
                value={field.value}
                id={field.name}
                error={!!fieldState.error}
                { ...showHelperMsg && {
                    helperText: fieldState.error ? fieldState.error.message : noBreakSpaceUni
                }}
                { ...startAdornment && {
                    InputProps: { 
                        startAdornment: (
                            <InputAdornment className='adornment' position='start'>
                                {startAdornment}
                            </InputAdornment>
                        ) 
                    }
                }}
            />
        </MolFieldEnhancements>
    )
)