import { useParams } from 'react-router-dom'
import { useAsyncFetch, useBreakpoints, useHeaderTitle } from 'src/app/hooks'
import { ConstructionCompanyService, RealEstateService } from 'src/services'
import { useEffect, useState } from 'react'
import { RealEstateLoading } from './components'
import { RealEstateMobile } from './mobile/mobile'
import { RealEstateDesktop } from './desktop/desktop'
import { ComponentFlags } from './types'
import { DEFAULT_COMPONENT_FLAGS, HIGHLIGHT_STATUS } from './utils'
import { AlbumType } from 'src/enums'
import './styles.scss'

export const RealEstate = () => {

    const { id } = useParams()
    const { updateTitle } = useHeaderTitle()
    const { appType } = useBreakpoints()

    const [flags, setFlags] = useState<ComponentFlags>(DEFAULT_COMPONENT_FLAGS)

    const { data: realEstate } = useAsyncFetch({
        callback: () => {
            if(!id) return
            return RealEstateService.find(id)
        },
        errorMessage: 'Erro ao buscar o empreendimento'
    }, [])

    const { data: constructionCompany } = useAsyncFetch({
        callback: () => {
            if(!realEstate) return
            return ConstructionCompanyService.find(realEstate.constructionCompanyId)
        },
        errorMessage: 'Erro ao buscar o empreendimento'
    }, [realEstate])

    useEffect(() => {
        if (!realEstate || !realEstate?.constructionCompany) return

        const { name, nickname } = realEstate.constructionCompany

        updateTitle(nickname || name)
    }, [realEstate])

    useEffect(() => {
        if(!realEstate) return

        setFlags({
            videos: Boolean(realEstate.videoUrls && realEstate.videoUrls.length > 0),
            albums: Boolean(realEstate.albums && realEstate.albums.find((item) => item.type === AlbumType.main)),
            buildingPlans: Boolean(realEstate.buildingPlans && realEstate.buildingPlans.length > 0),
            buildingPlansTable: Boolean(realEstate.buildingPlans && realEstate.buildingPlans.length > 1),
            map: Boolean(realEstate.address.location),
            constructionCompany: HIGHLIGHT_STATUS.includes(realEstate.status),
        })
    }, [realEstate])

    return (
        <div className='real-estate-page'>
            { (!realEstate || !constructionCompany) && <RealEstateLoading /> }
            { realEstate && constructionCompany && appType === 'mobile' && (
                <RealEstateMobile flags={flags} data={{ realEstate, constructionCompany }} />
            )}
            { realEstate && constructionCompany && appType === 'desktop' && (
                <RealEstateDesktop flags={flags} data={{ realEstate, constructionCompany }} />
            )}
        </div>
    )
}