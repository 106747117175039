import { Dialog, DialogContent, IconButton } from '@mui/material'
import { PropsWithChildren } from 'react'
import './modal.scss'

type AtomModalProps = {
    flag: boolean
    onClose: () => void
    className?: string
    paperClassName?: string
    padding?: 'normal' | 'small'
    width?: string
    closeButton?: boolean
}

export const AtomModal = ({
    flag,
    onClose,
    className,
    paperClassName,
    padding = 'normal',
    width,
    children,
    closeButton = true,
}: PropsWithChildren<AtomModalProps>) => (
    <Dialog 
        open={flag} 
        onClose={onClose}
        className={`atom-modal ${padding}-padding`}
        {...width && { PaperProps: { sx: { width } } }}
        {...paperClassName && { PaperProps: { className: paperClassName } }}
    >
        {closeButton && <IconButton onClick={onClose} className='close-btn'>
            <i className='fi fi-rr-cross'></i>
        </IconButton>
        }
        <DialogContent className={`atom-modal-content ${className || ''}`}>
            { children }
        </DialogContent>
    </Dialog>
)