import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import './tag.scss'

type Props = {
    label: string
    size?: 'tags' | 'body'
}

export const AtomTag = ({ label, size = 'tags' }: Props) => {
    const { appType } = useBreakpoints()

    return (
        <div className={`atom-tag ${size}`}>
            <Typography variant={`${appType}-${size}`}>
                { label }
            </Typography>
        </div>
    )
}
