import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { propsToElements } from './utils'
import { RealEstate } from 'src/types'
import './building-plans-table.scss'
import { useBreakpoints } from 'src/app/hooks'

type OrgBuildingPlansTable = {
    data: RealEstate
}

export const OrgBuildingPlansTable = ({ data: { buildingPlans } }: OrgBuildingPlansTable) => {

    const { appType } = useBreakpoints()

    const navigate = useNavigate()

    const rows = useMemo(() => (
        propsToElements.map(({ icon, element }) => {
            const data = buildingPlans!.map(buildingPlan => element(buildingPlan, navigate))
            return [icon, ...data]
        })
    ), [buildingPlans])

    return (
        <div className='org-building-plans-table'>
            <div className='box'>
                <Typography variant={`${appType}-h3`}>
                    Resumo de Preços
                </Typography>
            </div>
            <TableContainer className='table-container'>
                <Table>
                    <TableHead>
                        <TableRow className='gray-row'>
                            <TableCell></TableCell>
                            {buildingPlans!.map(({ id, name }) => (
                                <TableCell key={id}>
                                    <Typography variant='mobile-h6'>
                                        { name }
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((cells, i) => (
                            <TableRow key={i} className={i % 2 === 1 ? 'gray-row' : ''}>
                                {cells.map((cell, j) => (
                                    <TableCell key={j}>
                                        {typeof cell === 'string'
                                            ? <i className={`fi ${cell}`}></i>
                                            : cell
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}