import { Typography } from '@mui/material'
import { AtomBottomSheet, OrgRealEstateEngage } from 'src/app/components'

import './building-plan-details.scss'


export type BuildingPlanDetailsProps = {
    flag: boolean;
    close: () => void;
    paymentConditions: Array<string>;
    price: string
}

export const BuildingPlanDetails = ({ close, flag, paymentConditions, price }: BuildingPlanDetailsProps) => {

    function formatPrice(price: string): string {
        const number = parseFloat(price)
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
        }).format(number)
    }

    return (
        <AtomBottomSheet isOpen={flag} onClose={close}>
            <div className='building-plan-details'>
                <Typography className='title' color='primary'>
                    Detalhes
                </Typography>

                <div className='price'>
                    <Typography variant='mobile-body' className='label'>
                        A partir de
                    </Typography>
                    <Typography className='value'>
                        {formatPrice(price)}
                    </Typography>
                </div>

                <ul>
                    {paymentConditions.map((paymentCondition, index) => {
                        return (
                            <li key={index}>
                                <Typography variant='mobile-body'>
                                    {paymentCondition}
                                </Typography>
                            </li>
                        )
                    })}
                </ul>

                <OrgRealEstateEngage onClick={close} />
            </div>
        </AtomBottomSheet>
    )
}