import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { RevertState } from 'src/enums'
import { RealEstate } from 'src/types'
import { formatCurrency, noBreakSpaceUni } from 'src/utils'
import { useBreakpoints } from 'src/app/hooks'
import './real-estate-mini-card.scss'

type RealEstateMiniCardProps = {
    data: RealEstate
    showOldPrice?: boolean
    highlightedPrice?: boolean
    itemId: number
}

export const MolRealEstateMiniCard = ({ 
    data,
    highlightedPrice = true,
    showOldPrice = true
}: RealEstateMiniCardProps) => {
    
    const { appType } = useBreakpoints()
    const navigate = useNavigate()

    const { 
        id,
        name,
        urls,
        address: {
            city,
            state
        },
        currentValue, 
        startingValue,
    } = data

    const formattedStartingValue = useMemo(() => (
        formatCurrency(startingValue)
    ), [startingValue])

    const formattedCurrentValue = useMemo(() => (
        currentValue ? formatCurrency(currentValue).split(',') : formattedStartingValue.split(',')
    ), [startingValue])

    const navigateHandler = useCallback(() => navigate(`/real-estate/${id}`), [id])

    return (
        <Card className='mol-real-estate-mini-card' onClick={navigateHandler}>
            <CardMedia image={urls[0]} sx={{ height: 140 }} />
            <CardContent>
                <Typography className='name' variant={appType === 'mobile' ? 'mobile-h6' : 'desktop-h7'}>
                    { name }
                </Typography>
                { appType === 'desktop' && (
                    <div className='spacer'></div>
                )}
                <Typography variant={appType === 'mobile' ? 'mobile-miniText' : 'desktop-caracAptos'}>
                    {city}/{RevertState[state]}
                </Typography>
                { showOldPrice && (
                    <Typography variant={appType === 'mobile' ? 'mobile-miniText' : 'desktop-tags'} className='old-price'>
                        { formattedCurrentValue ? formattedStartingValue : noBreakSpaceUni }
                    </Typography>
                )}
                <Typography variant={`${appType}-button`} {...highlightedPrice && { color: 'primary' }}>
                    { formattedCurrentValue[0] } 
                    <Typography variant={`${appType}-miniText`} {...highlightedPrice && { color: 'primary' }} fontWeight='bold'>
                        ,{ formattedCurrentValue[1] }
                    </Typography>
                </Typography>
            </CardContent>
        </Card>
    )
}