import { FooterMenu } from './types'
import { Link } from 'react-router-dom'
import './footer-menus.scss'
import { RealEstateStatus } from 'src/enums'

const menus: FooterMenu[] = [
    {
        title: 'Encontre seu imóvel',
        items: [
            { 
                label: 'Lançamentos imobiliários', 
                url: `/real-estate?status=[${RealEstateStatus.launch}]` 
            },
            { 
                label: 'Imóveis na planta em construção', 
                url: `/real-estate?status=[${RealEstateStatus.underConstruction}]`
            },
            { 
                label: 'Imóveis novos e prontos para morar', 
                url: `/real-estate?status=[${RealEstateStatus.readyToLive}]` 
            },
            { 
                label: 'Imóveis de repasses', 
                url: `/real-estate?status=[${RealEstateStatus.preOwnedAndStock}]` 
            },
        ]
    },
    {
        title: 'Quem somos',
        items: [
            { label: 'Hub House', url: '1' },
            { label: 'Anúnciar imóveis', url: '2' },
            { label: 'Fale conosco', url: '3' },
        ]
    }
]

export const MolFooterMenus = () => {
    return (
        <div id='mol-footer-menus'>
            { menus && menus.map(menu => (
                <details 
                    key={menu.title}
                    open={true}
                    className='menu'
                >
                    <summary>
                        {menu.title}
                        <i className='fi fi-bs-angle-down'></i>
                    </summary>
                    <ul>
                        {menu.items.map(item => (
                            <li key={item.url}>
                                <Link to={item.url}>
                                    {item.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </details>
            )) }
        </div>
    )
}