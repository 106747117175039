import { FC, useEffect, useState } from 'react'
import { AtomBottomSheet, AtomPrice, OrgRealEstateEngage } from 'src/app/components'
import { RealEstateComponentProps } from '../types'
import './bottom-sheet.scss'

export const RealEstateBottomSheet: FC<RealEstateComponentProps> = ({ data }) => {
    const [engageFlag, setEngageFlag] = useState<boolean>(false)

    useEffect(() => {
        const scrollableEl = document.querySelector('main')

        const handler = () => {
            setTimeout(() => setEngageFlag(true), 1000)
        }

        if(scrollableEl) {
            scrollableEl.addEventListener('scroll', handler, { once: true })
            return () => scrollableEl.removeEventListener('scroll', handler)
        }
    }, [])

    return (
        <AtomBottomSheet 
            variant='persistent' 
            paddingType='small'
            isOpen={engageFlag} 
            onClose={() => setEngageFlag(false)}
        >
            <div className='real-estate-bottom-sheet'>
                <AtomPrice
                    format='buy_it_for'
                    align='left'
                    valueColor='text'
                    startingValue={data.startingValue}
                    currentValue={data.currentValue}
                />

                <OrgRealEstateEngage
                    btnVariant='secondary'
                    onClick={() => setEngageFlag(false)}
                />
            </div>
        </AtomBottomSheet>
    )
}