import './styles.scss'
import { useLocation, useParams } from 'react-router-dom'
import { useAsyncFetch, useBreakpoints } from 'src/app/hooks'
import { Album } from 'src/types'
import { AlbumService, RealEstateService,  } from 'src/services'
import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { AtomCarousel } from 'src/app/components/atoms'

interface MyAlbumType {
    createdAt: string,
    id: number,
    name: string,
    realEstateId: number,
    type: string,
    updatedAt: string, 
    urls: string[]
}

export const Gallery = () => {
    const { id } = useParams()
    const { hash } = useLocation()
    const allPhotos: Array<any> = []

    const { appType } = useBreakpoints()

    const { data: RealEstate } = useAsyncFetch({
        callback: () => {
            if(!id) return
            return RealEstateService.find(id)
        },
        errorMessage: 'Erro ao buscar construtoras'
    }, [])


    const { data: Gallery } = useAsyncFetch({
        callback: () => {
            if(!id) return
            return AlbumService.listByRealEstateId(id)
        },
        formatter: (albums: Album[]) => albums.filter(album => album.urls.length > 0),
        errorMessage: 'Erro ao buscar construtoras'
    }, [id])


    Gallery?.forEach(album => {
        album.urls.forEach(url => {
            url
            allPhotos.push({
                album: album.id,
                url: url,
                albumName: album.name
            })
        })
    })

    const [sessionInfo, setSessionInfo] = useState('')
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0)
    const [selectedImage, setSelectedImage] = useState<string | null>(null)
    const [selectedAlbum, setSelectedAlbum] = useState<MyAlbumType | null>(null)

    useEffect(() => {
        if (!hash) return
        setSessionInfo(hash.substring(1)) 
    }, [hash])

    useEffect(() => {
        const handleLinkClick = (event: Event) => {
            event.preventDefault()
            const target = event.currentTarget as HTMLAnchorElement
            const targetId = target.getAttribute('href')?.substring(1)
            const targetElement = document.getElementById(targetId || '') as HTMLElement
            const albumsElement = document.querySelector('.albums') as HTMLElement
    
            if (targetElement && albumsElement) {
                const targetPosition = targetElement.offsetTop - albumsElement.offsetTop
                albumsElement.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                })
            }
        }
    
        const links = document.querySelectorAll('.albums a')
        links.forEach(link => {
            link.addEventListener('click', handleLinkClick)
        })
    
        return () => {
            links.forEach(link => {
                link.removeEventListener('click', handleLinkClick)
            })
        }
    }, [Gallery])

    useEffect(() => {
        const updateSessionInfoBasedOnScroll = () => {
            const albums = Gallery ? Gallery.map(album => document.getElementById(album.name)).filter((element): element is HTMLElement => element !== null) : []
            let closestAlbum: HTMLElement | null = null
            let minDistance = Infinity
    
            albums.forEach(album => {
                const rect = album.getBoundingClientRect()
                console.log(rect)
                const distance = Math.abs(rect.top - 120) 
        
                if (distance < minDistance) {
                    closestAlbum = album
                    minDistance = distance
                }
            })
    
            if (closestAlbum !== null) {
                const closestAlbumId = (closestAlbum as HTMLElement).id
                setSessionInfo(closestAlbumId)
            }
        }
    
        const albumsElement = document.querySelector('.albums')
        if (albumsElement) {  
            albumsElement.addEventListener('scroll', updateSessionInfoBasedOnScroll)
    
            return () => {
                albumsElement.removeEventListener('scroll', updateSessionInfoBasedOnScroll)
            }
        }
    }, [Gallery])

    // faz o scroll do menu para o item selecionado (verde) ficar sempre visível na tela (serve para o mobile, pois no desktop todos os botões de sessão aparecem)
    useEffect(() => {
        const selectedElement = document.querySelector('.type-selected')
        if (selectedElement) {
            selectedElement.scrollIntoView()
        }
    }, [sessionInfo])



    const handleImageClick = (index: number, photoUrl: string, album: MyAlbumType) => {
        setSelectedImage(photoUrl)
        setSelectedAlbum(album)
        setSessionInfo(album.name)
        setSelectedImageIndex(allPhotos.findIndex(photo => photo.url === photoUrl && photo.album === album.id))
    }
    
    const closeImageView = () => {
        setSelectedImage(null)
    }

    const handleIndexChange = (index: number) => {
        const selectedAlbum = allPhotos[index].albumName
        setSessionInfo(selectedAlbum)   
    }


    // muda para a primeira foto do album clicado no image view
    const changeSessionOnImageView = (album: MyAlbumType) => {
        const firstAlbumPhoto = allPhotos.findIndex(photo => photo.album === album.id)
        setSelectedImageIndex(firstAlbumPhoto)
        setSessionInfo(album.name)
    }

    return (
        <div className={selectedImage ? 'image-view' : 'gallery'}>
            
            <div className='header'>
                <div className='back-page'  onClick={selectedImage ? closeImageView : () => {}}>
                    <i className={appType === 'mobile' ? 'fi fi-rs-angle-left' : 'fi fi-rs-arrow-small-left'}></i>
                    {(appType === 'desktop' && !selectedImage) &&
                        <div className='title'>
                            <Typography variant={'desktop-h3'}>
                                Galeria
                            </Typography>
                            <hr/>
                        </div>
                    }
                </div>
                <ScrollMenu
                    LeftArrow={null}
                    RightArrow={null}
                    scrollContainerClassName="scroll"
                    itemClassName="scroll-item"
                >
                    {(Gallery || []).map(album => (
                        <div key={album.id}  onClick={selectedImage ? () => changeSessionOnImageView(album) : () => {}}  className={sessionInfo === album.name ? 'type-selected' : 'type'} >
                            <a href={`#${album.name}`}>
                                {album.name}
                            </a>
                        </div>
                    ))}
                </ScrollMenu>    
            </div>
            {!selectedImage && (
                <div className='albums'>
                    {Gallery &&  Gallery.map(album => {
                        let row = 0
                        let count = 0
                        return (
                            <>
                                <hr style={{ height: '2px', backgroundColor: '#EFEFEF', border: 'none' }} />
                                <div key={album.id} id={`${album.name}`} className='album'>
                                    <div className='album-title'>
                                        <Typography variant={`${appType}-h3`}>
                                            {album.name}
                                        </Typography>
                                    </div>
                                    <div className='row'>


                                        {album.urls.map((photoUrl, index) => {
                                            // salva a última linha para a próxima imagem
                                            const previousRow = row

                                            if(appType === 'mobile') {
                                                // quando a linha for par, só muda a linha
                                                // quando a linha for impar, o count faz 1, 2
                                                if(row % 2 === 0) row += 1
                                                else {
                                                    count +=1
                                                    if(count === 2) {
                                                        count = 0
                                                        row += 1
                                                    }    
                                                }

                                                // const fullPhoto = album.urls.length -1 === index && count == 1 ? true : false
                                            } else {
                                                // quando a linha for par, conta até 3
                                                // quando a linha for impar, conta 2

                                                if(row % 2 === 0) {
                                                    count += 1
                                                    if(count === 3) {
                                                        count = 0
                                                        row += 1
                                                    }
                                                } else {
                                                    count += 1
                                                    if(count === 2) {
                                                        count = 0
                                                        row += 1
                                                    }
                                                }

                                                // const fullPhoto = album.urls.length -1 === index && count == 1 ? true : false
                                                // const halfPhoto = album.urls.length -1 === index && count == 2 ? true : false
                                            }
                                            
                                            return (
                                                
                                                <img 
                                                    key={index}
                                                    src={photoUrl}
                                                    alt={photoUrl}
                                                    onClick={() => handleImageClick(index, photoUrl, album)}
                                                    // className={album.urls.length -1 === index && count == 1 ? 'photo' : previousRow % 2 === 0 ? 'photo' : 'photo-half'}
                                                    className={previousRow % 2 === 0 ? 'photo' : 'photo-half'}
                                                />
                                            )
                                        })}
                                    </div>

                                
                                </div>
                            </>
                        )
                    })}
                </div>
            )}

            {selectedImage && (
                <div className='image-view-content'>
                    {selectedAlbum && 
                        <AtomCarousel startAt={selectedImageIndex} onIndexChange={handleIndexChange}>
                            {allPhotos.map((photo, index) => (
                                <img key={index} src={photo.url} />
                            ))}
                        </AtomCarousel>
                    }
                </div>
            )}
        </div>
    )
}