import { useBreakpoints } from 'src/app/hooks'
import { AtomButton, AtomPlaceholder } from '../../atoms'
import { Typography } from '@mui/material'

import './join-us.scss'
import { useNavigate } from 'react-router-dom'


type OrgJoinUsProps = {
    visualizeAllButton?: boolean
}


export const OrgJoinUs = ({ visualizeAllButton = false }: OrgJoinUsProps) => {
    
    const {appType} = useBreakpoints()

    const navigate = useNavigate()

    return (
        <div className='org-join-us'>
            <AtomPlaceholder/>
            
            <div className='description'>
                <Typography variant={appType === 'mobile' ? 'mobile-h3' : 'desktop-h1'}>
                    Venha fazer parte do nosso hub de imóveis
                </Typography>

                <Typography variant={`${appType}-body`}>
                    Divulgue os seus lançamentos na melhor plataforma do mercado.
                </Typography>
                <div className='buttons'>
                    <div className='add-construction-company'>
                        <AtomButton>
                            Adicionar a minha construtora
                        </AtomButton>

                    </div>
                    <div className='visualize-all-button'>
                        { visualizeAllButton && (
                            <AtomButton variant='contained' onClick={() => navigate('/construction-company')}>
                                <Typography variant='mobile-button'>
                                    Visualizar todas
                                </Typography>
                            </AtomButton>
                        )}  
                    </div>
                </div>
            </div>
        </div>


    )
}