import { Checkbox, FormControlLabel } from '@mui/material'
import MolWithController from '../with-controller/with-controller'
import { MolCheckboxProps } from './types'
import './checkbox-field.scss'

export const MolCheckboxField = MolWithController<MolCheckboxProps, string>(
    ({
        field,
        fieldState,
        formState: _formState,
        label,
        triggerDependencies,
        ...props
    }) => (
        <FormControlLabel 
            label={label}
            className='checkbox-field'
            control={
                <Checkbox 
                    {...props} 
                    disabled={field.disabled}
                    onBlur={field.onBlur}
                    onChange={(event) => {
                        field.onChange(event)
                        triggerDependencies()
                    }}
                    ref={field.ref}
                    checked={field.value}
                    id={field.name}
                />
            }
        />
    )
)
