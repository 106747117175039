import { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { MolFieldEnhancements } from '../field-enhancements/field-enhancements'
import WithController from '../with-controller/with-controller'
import { AutocompleteFieldProps } from './types'
import { useAsyncFetch } from 'src/app/hooks'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import { noBreakSpaceUni } from 'src/utils'
import './autocomplete-field.scss'

export const MolAutocompleteField = WithController<AutocompleteFieldProps, any>(
    ({
        field,
        fieldState,
        formState: _formState,
        label,
        triggerDependencies,
        id,
        searchCallback,
        getOptionLabel,
        showHelperMsg = true,
        groupBy,
        placeholder,
        labelColor,
        endAdornment,
        layout = 'normal'
    }) => {
        const [isOpen, setIsOpen] = useState<boolean>(false)
        const [search, setSearch] = useState<string>('')

        const noOptionsText = useMemo(() => (
            search.length === 0 
                ? 'Comece a digitar para pesquisar' 
                : 'Ops... Não encotnramos nenhum resultado. Por favor, tente outro.'
        ), [search])
    
        const onInputChange = (event: SyntheticEvent, value: string) => {
            setSearch(value)
        }

        const onChangeHandler = useCallback((event: SyntheticEvent, value: string) => {
            field.onChange(value)
            triggerDependencies()
        }, [])
    
        const { data, loading } = useAsyncFetch({
            callback: () => searchCallback(search),
            debounceTime: 250,
            errorMessage: 'Ocorreu um erro ao pesquisar',
        }, [search])

        return (
            <MolFieldEnhancements
                label={label}
                field={field}
                fieldState={fieldState}
                layout={layout}
                labelColor={labelColor}
                id={id}
            >
                <Autocomplete
                    open={search.length > 0 && isOpen}
                    className='mol-auto-complete-field'
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    value={field.value}
                    onChange={onChangeHandler}
                    inputValue={search}
                    onInputChange={onInputChange}
                    options={data || []}
                    groupBy={groupBy}
                    getOptionLabel={getOptionLabel}
                    loading={loading}
                    loadingText='Carregando...'
                    noOptionsText={noOptionsText}
                    fullWidth
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={`mol-text-field ${layout}`}
                            disabled={field.disabled}
                            onBlur={field.onBlur}
                            variant='outlined'
                            ref={field.ref}
                            error={!!fieldState.error}
                            placeholder={placeholder}
                            { ...showHelperMsg && {
                                helperText: fieldState.error ? fieldState.error.message : noBreakSpaceUni
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: endAdornment 
                                    ? (
                                        <InputAdornment sx={{ marginRight: '-20px' }} position='end'>
                                            <i className={`fi ${endAdornment}`}></i>
                                        </InputAdornment>
                                    )
                                    : null
                            }}
                        />
                    )}
                />
            </MolFieldEnhancements>
        )
    }
)