import { Typography } from '@mui/material'
import './page-placeholder.scss'

type Props = {
    name: string
}

export const AtomPagePlaceholder = ({ name }: Props) => (
    <div className='atom-page-placeholder'>
        <i className='fi fi-rr-tools'></i>
        <Typography className='title'>
            Página { name }
        </Typography>
        <Typography className='text'>
            Ainda estamos trabalhando para criar isso para você, mas logo tudo ficará pronto!
        </Typography>
    </div>
)