import { Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { AtomLogo } from '../../atoms'
import { DrawerItem } from './types'
import { useNavigate } from 'react-router-dom'
import { OrgSearch } from '../../organisms'
import './header-drawer.scss'

export const MolHeaderDrawer = () => {

    const navigate = useNavigate()

    const [drawerFlag, setDrawerFlag] = useState<boolean>(false)

    const openDrawer = () => setDrawerFlag(true)
    const closeDrawer = () => setDrawerFlag(false)

    const goTo = (url: string) => {
        closeDrawer()
        navigate(url)
    }

    const openSearch = () => {
        closeDrawer()
        OrgSearch.open()
    }

    const openContacts = () => {
        closeDrawer()
    }

    const items: DrawerItem[] = useMemo(() => [
        { label: 'Empreendimentos', icon: 'fi-br-search', action: () => openSearch() },
        { label: 'Construtoras', icon: 'fi-br-builder', action: () => goTo('/construction-company') },
        { label: 'Sobre', icon: 'fi-br-briefcase', action: () => {} },
        { label: 'Dúvidas', icon: 'fi-br-interrogation', action: () => goTo('/help') },
        { label: 'Fale conosco', icon: 'fi-brands-whatsapp', action: () => openContacts() },
    ], [])

    return (
        <div id='mol-header-drawer'>
            <IconButton onClick={openDrawer}>
                <i className='fi fi-br-menu-burger'></i>
            </IconButton>
            <Drawer 
                anchor='left'
                open={drawerFlag}
                onClose={closeDrawer}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
            >
                <div className='mol-header-drawer-content'>
                    <div className='head'>
                        <AtomLogo type='blue' />
                        <IconButton onClick={closeDrawer}>
                            <i className='fi fi-bs-x'></i>
                        </IconButton>
                    </div>
                    <div className='links'>
                        <Typography variant='mobile-h3'>
                            Menu
                        </Typography>
                        <List>
                            { items.map(item => (
                                <ListItemButton key={item.icon} onClick={item.action}>
                                    <ListItemIcon>
                                        <i className={item.icon}></i>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='mobile-h5'>
                                            { item.label }
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            ))}
                        </List>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}