import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import { AtomDivider, AtomModal } from '../../atoms'
import { useEffect, useState } from 'react'
import { MolRating } from '../rating/rating'
import { ConstructionCompany } from 'src/types'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import './ratings.scss'

export const MolRatings = ({ constructionCompany }: { constructionCompany: ConstructionCompany }) => {
    const { appType } = useBreakpoints()

    const [orderType, setOrderType] = useState('Mais alta')
    const [ratingsModal, setRatingsModal] = useState<boolean>(false)
    const onOpenRatingsModal = () => setRatingsModal(true)
    const onCloseRatingsModal = () => setRatingsModal(false)

    const { reviews: initialReviews } = constructionCompany

    const [reviews, setReviews] = useState(initialReviews)
    useEffect(() => {
        orderReviews('Mais alta')
    }, [])

    function orderReviews(order: string) {
        setOrderType(order)
        if (reviews !== undefined) {
            let sortedReviews
            switch (order) {
            case 'Mais recentes':
                sortedReviews = [...reviews].sort((a, b) => {
                    const parseDate = (dateString: string) => {
                        const [day, month, year] = dateString.split('/').map(Number)
                        return new Date(year, month - 1, day)
                    }

                    const dateA = parseDate(a.time)
                    const dateB = parseDate(b.time)

                    return dateB.getTime() - dateA.getTime()
                })
                break
            case 'Mais alta':
                sortedReviews = [...reviews].sort((a, b) => b.rating - a.rating)
                break
            case 'Mais baixa':
                sortedReviews = [...reviews].sort((a, b) => a.rating - b.rating)
                break
            default:
                sortedReviews = reviews
                break
            }
            setReviews(sortedReviews)
        }
    }
   
    return (
        <>
            <span onClick={onOpenRatingsModal}> Ver comentários </span>
            
            <AtomModal 
                flag={ratingsModal} 
                onClose={onCloseRatingsModal}
                closeButton={false}
                padding='small' 
                paperClassName='custom-modal-size'
            >
                <div className='modal-ratings'>
                    <div className='header'>
                        <Typography variant={`${appType}-h3`}>Avaliações</Typography>

                        
                        <div className='sroll-menu-and-close-icon'>
                            {appType === 'desktop' && (
                                <ScrollMenu
                                    LeftArrow={null}
                                    RightArrow={null}
                                    scrollContainerClassName="scroll"
                                    itemClassName="scroll-item"
                                >
                                    <div className='types'>
                                        {['Mais recentes', 'Mais alta', 'Mais baixa'].map((chip, index) => (
                                            <div key={index} onClick={() => orderReviews(chip)} className={orderType === chip ? 'type-selected' : 'type'}>
                                                {chip}
                                            </div>
                                        ))}
                                    </div>
                                </ScrollMenu>
                            )}

                            <div className='close-button' onClick={onCloseRatingsModal}>
                                <i className='fi fi-br-cross'></i>
                            </div>
                        </div>

                    </div>

                    <div className='reviews'>
                        <div className='content'>
                            {reviews && reviews.map((review, index) =>{
                                return (
                                    <>
                                        <MolRating key={index} review={review}></MolRating>
                                        { index !== reviews.length - 1 && (
                                            <AtomDivider className='divider'></AtomDivider>
                                        )}
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </AtomModal>     
        </>     
    )
}