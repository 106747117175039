import { FindResult } from 'src/types'
import { Axios } from 'src/utils'

class CoreService {
    async find(substr: string) {
        const { data } = await Axios.get<FindResult[]>('/find', { params: { substr } })
        return data
    }
}

export default new CoreService()
