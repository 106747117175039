import { Avatar } from '@mui/material'
import { AtomAvatarProps } from './types'
import './avatar.scss'

export const AtomAvatar = ({
    src,
    size = 'medium',
    gradientBorder = false,
}: AtomAvatarProps) => {
    return (
        <div className={`atom-avatar ${size} ${gradientBorder ? 'border' : ''}`}>
            <Avatar
                className={size}
                src={src}
            />
        </div>
    )
}