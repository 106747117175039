import { Typography } from '@mui/material'
import { useAsyncFetch, useBreakpoints, useForm } from 'src/app/hooks'
import { ConstructionCompaniesFilter } from './types'
import { ConstructionCompaniesForm, defaultValues, validationSchema } from './form-controller'
import { ConstructionCompanyCard, OrgJoinUs } from 'src/app/components'
import { AddressService, ConstructionCompanyService } from 'src/services'
import './styles.scss'

export const ConstructionCompanies = () => {
    
    const {appType} = useBreakpoints()

    const { data: cityOptions } = useAsyncFetch({
        callback: () => AddressService.cities(),
        formatter: addresses => addresses.map(address => ({
            label: address.city,
            value: address.city
        })),
        errorMessage: 'Erro ao buscar as cidades'
    }, [])

    const formObject = useForm<ConstructionCompaniesFilter>({
        validationSchema: validationSchema,
        defaultValues: defaultValues
    })

    const city = formObject.form.watch('addressCity')

    const {data: companies} = useAsyncFetch({
        callback: () => ConstructionCompanyService.list({ realEstatesCity: city }),
        errorMessage: 'Erro ao buscar construtoras'
    }, [city])

    return (
        <div className='construction-companies-page box'>
            <div className='title'>
                <Typography
                    variant={appType === 'mobile' ? 'mobile-h3' : 'desktop-h1'} 
                    {...appType === 'desktop' && {color: 'primary', className: 'desktop-subtitle'}}
                >
                    Construtoras e Incorporadoras
                </Typography>
                
                <Typography variant={`${appType}-body`} className={`${appType}-subtitle`}>
                    Confira as construtoras parceiras cadastradas na plataforma
                </Typography>
            </div>
            
            <ConstructionCompaniesForm formObject={formObject} id='form'>
                <ConstructionCompaniesForm.Select
                    name='addressCity'
                    options={cityOptions || []}
                    {...appType === 'mobile' && {label: 'Empresa com empreendimento em:'}}
                />
            </ConstructionCompaniesForm>

            <div className="cards-container">
                {companies && companies.map(company => (
                    <div key={company.id} className="construction-company-card">
                        <ConstructionCompanyCard data={company} />
                    </div>
                ))}
            </div>

            <OrgJoinUs/>
        </div>
    )
}