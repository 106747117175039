import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AtomButton, AtomCarousel } from 'src/app/components'
import { useAsyncFetch, useBreakpoints } from 'src/app/hooks'
import { useMemo } from 'react'
import './styles.scss'
import { ConstructionCompanyService } from 'src/services'

export const HomeJoinUs = () => {

    const { appType } = useBreakpoints()

    const navigate = useNavigate()

    const { data: data } = useAsyncFetch({
        callback: () => ConstructionCompanyService.list({ rowsPerPage: 6 }),
        errorMessage: 'Ocorreu um erro ao carregar as construtoras'
    }, [])

    const title = useMemo(() => (
        <Typography variant={appType === 'mobile' ? 'mobile-h3' : 'desktop-h1'} className='title'>
            Venha fazer parte do melhor Hub de imóveis
        </Typography>
    ), [appType])

    const carousel = useMemo(() => (
        <AtomCarousel arrowColor='grey'>
            {(data || []).map(({ id, profilePictureUrl }) => (
                <img 
                    key={id} 
                    className='construction-company-logo'
                    style={{ backgroundImage: `url(${profilePictureUrl})` }}
                />
            ))}
        </AtomCarousel>
    ), [data])

    const squares = useMemo(() => (
        <>
            {(data || []).map(({ id, profilePictureUrl }) => (
                <img 
                    key={id} 
                    style={{ backgroundImage: `url(${profilePictureUrl})` }}
                    className='construction-company-logo'
                />
            ))}
        </>
    ), [data])

    const subtitle = useMemo(() => (
        <Typography variant={`${appType}-body`} className='subtitle'>
            Divulgue os seus lançamentos na melhor plataforma do mercado.
        </Typography>
    ), [appType])

    const addBtn = useMemo(() => (
        <AtomButton variant='primary'>
            Adicionar minha construtora
        </AtomButton>
    ), [])

    const redirectBtn = useMemo(() => (
        <AtomButton variant='contained' onClick={() => navigate('/construction-company')}>
            Visualizar todas
        </AtomButton>
    ), [])

    const mobileLayout = useMemo(() => (
        <div className='home-join-us box'>
            { title }
            { carousel }
            { subtitle }
            { addBtn }
            { redirectBtn }
        </div>
    ), [title, carousel, subtitle, addBtn, redirectBtn])

    const desktopLayout = useMemo(() => (
        <div className='home-join-us'>
            <div className='content box'>
                <div className='empty-column'></div>
                <div className='left-column'>
                    { title }
                    { subtitle }
                    <div className='buttons'>
                        { addBtn }
                        { redirectBtn }
                    </div>
                </div>
                <div className='empty-column'></div>
                <div className='right-column'>
                    { squares }
                </div>
                <div className='empty-column'></div>
            </div>
        </div>
    ), [title, subtitle, addBtn, redirectBtn, squares])

    return (
        <>
            { appType === 'mobile' ? mobileLayout : desktopLayout }
        </>
    )
}