import { useCallback, useEffect, useMemo, useState } from 'react'
import { defaultValues, RealEstateFiltersForm, validationSchemas } from './form-control'
import { RealEstateFormData } from 'src/types'
import { formatCurrency, getOptionsMap, humanizeValueArray, roomsQtyOptions, statusOptions } from 'src/utils'
import { useAsyncFetch, useBreakpoints, useForm, useQuery } from 'src/app/hooks'
import _ from 'lodash'
import { AddressService } from 'src/services'
import { RealEstatesModalBtn } from '../modal-btn'
import { MolFilterButton } from 'src/app/components'
import './styles.scss'

const roomsQtyMap = getOptionsMap(roomsQtyOptions)

type Props = {
    onSubmit: (val: RealEstateFormData) => void
}

export const RealEstatesFilters = ({ onSubmit }: Props) => {

    const { appType } = useBreakpoints()
    const { queryObj } = useQuery<RealEstateFormData>()

    const [filter, setFilter] = useState<RealEstateFormData>({
        ...defaultValues,
        ...queryObj,
    })

    const roomsQtyLabel = useMemo(() => {
        if(!filter.roomsQty || filter.roomsQty.length === 0) return 'Quartos'
        return humanizeValueArray(roomsQtyMap, filter.roomsQty, 'quartos')
    }, [filter.roomsQty, roomsQtyMap])

    const pricesLabel = useMemo(() => {
        const { priceMin, priceMax } = filter

        if(priceMin && !priceMax) return `A partir de ${formatCurrency(priceMin)}`

        if(!priceMin && priceMax) return `Até ${formatCurrency(priceMax)}`

        if(priceMin && priceMax) return `De ${formatCurrency(priceMin)} até ${formatCurrency(priceMax)}`

        return 'Preço'
    }, [filter.priceMin, filter.priceMax])
    
    const formObject = useForm<RealEstateFormData>({
        defaultValues: filter,
        validationSchema: validationSchemas,
    })

    const city = formObject.form.watch('city')

    const { data: cityOptions } = useAsyncFetch({
        callback: () => AddressService.cities(),
        formatter: addresses => addresses.map(address => ({
            label: address.city,
            value: address.city
        })),
        errorMessage: 'Erro ao buscar as cidades'
    }, [])

    const { data: neighborhoodOptions } = useAsyncFetch({
        callback: () => {
            if(!city) return
            return AddressService.neighborhoods(city)
        },
        formatter: addresses => addresses.map(address => ({
            label: address.neighborhood,
            value: address.neighborhood
        })),
        errorMessage: 'Erro ao buscar os bairros'
    }, [city])

    const submitHandler = useCallback(
        _.debounce((formValue: RealEstateFormData) => {
            onSubmit(formValue)
        }, 200), 
        []
    )

    const resetHandler = useCallback(() => {
        formObject.form.reset({
            ...formObject.form.getValues(),
            ...filter,
        })
    }, [formObject.form, filter])

    const updateFilters = useCallback(() => {
        setFilter(formObject.form.getValues())
    }, [])

    useEffect(() => {
        formObject.form.setValue('neighborhood', '')
    }, [city])

    useEffect(() => {
        submitHandler(filter)
    }, [filter])

    useEffect(() => {
        if(queryObj) {
            setFilter({...defaultValues, ...queryObj})
            formObject.form.reset({...defaultValues, ...queryObj})
        }
    }, [queryObj])

    return (
        <div className='real-estates-filters'>
            <RealEstateFiltersForm id='form' formObject={formObject}>
                { appType === 'mobile' && (
                    <div className='modal-btn'>
                        <RealEstatesModalBtn filters={formObject.form.getValues()} />
                    </div>
                )}
                <MolFilterButton 
                    id='city'
                    label={filter.city || 'Cidade'} 
                    onContentToggle={resetHandler} 
                    onSubmit={updateFilters}
                >
                    <RealEstateFiltersForm.Select
                        id='city'
                        name='city'
                        label='Cidade'
                        options={cityOptions || []}
                        {...appType === 'mobile' && { layout: 'mini' }}
                    />
                </MolFilterButton>
                { filter.city && (
                    <MolFilterButton 
                        id='neighborhood'
                        label={filter.neighborhood || 'Bairro'} 
                        onContentToggle={resetHandler} 
                        onSubmit={updateFilters}
                    >
                        <RealEstateFiltersForm.Select
                            id='neighborhood'
                            name='neighborhood'
                            label='Bairro'
                            options={neighborhoodOptions || []}
                            {...appType === 'mobile' && { layout: 'mini' }}
                        />
                    </MolFilterButton>
                )}
                <MolFilterButton 
                    id='roomsQty'
                    label={roomsQtyLabel}
                    onContentToggle={resetHandler} 
                    onSubmit={updateFilters}
                >
                    <RealEstateFiltersForm.Select
                        id='roomsQty'
                        name='roomsQty'
                        label='Quartos'
                        renderValuePosfix='quartos'
                        options={roomsQtyOptions}
                        {...appType === 'mobile' && { layout: 'mini' }}
                        multiple
                    />
                </MolFilterButton>
                <MolFilterButton 
                    id='status'
                    label='Status do imóvel'
                    badge={filter.status?.length || 0} 
                    onContentToggle={resetHandler} 
                    onSubmit={updateFilters}
                >
                    <RealEstateFiltersForm.Select
                        id='status'
                        name='status'
                        label='Status do imóvel'
                        options={statusOptions}
                        {...appType === 'mobile' && { layout: 'mini' }}
                        multiple
                    />
                </MolFilterButton>
                <MolFilterButton 
                    id='prices'
                    label={pricesLabel} 
                    onContentToggle={resetHandler} 
                    onSubmit={updateFilters}
                >
                    <div className='real-estate-filters-prices'>
                        <RealEstateFiltersForm.TextField 
                            id='priceMin'
                            name='priceMin'
                            type='number'
                            startAdornment='R$'
                            label='Preço mínimo'
                            showHelperMsg={false}
                            {...appType === 'mobile' && { layout: 'mini' }}
                        />
                        <div className='stroke'></div>
                        <RealEstateFiltersForm.TextField 
                            id='priceMax'
                            name='priceMax'
                            type='number'
                            label='Preço máximo'
                            startAdornment='R$'
                            showHelperMsg={false}
                            {...appType === 'mobile' && { layout: 'mini' }}
                        />
                    </div>
                </MolFilterButton>
                { appType === 'desktop' && (
                    <div className='modal-btn'>
                        <RealEstatesModalBtn filters={formObject.form.getValues()} />
                    </div>
                )}
            </RealEstateFiltersForm>
        </div>
    )
}