import { Typography } from '@mui/material'
import { RealEstate } from 'src/types'
import { useBreakpoints } from 'src/app/hooks'
import { AtomUnderlinedText } from '../../atoms'
import './real-estate-description.scss'

type MolRealEstateDescriptionProps = {
    data: RealEstate
    className?: string
}

export const MolRealEstateDescription = ({
    data: {
        name,
        description
    },
    className
}: MolRealEstateDescriptionProps) => {
    const { appType } = useBreakpoints()

    return (
        <div className={`mol-real-estate-description ${className || ''}`}>
            <AtomUnderlinedText showUnderline={appType === 'desktop'}>
                <Typography variant={`${appType}-h3`}>
                    Conheça o {name}
                </Typography>
            </AtomUnderlinedText>
            <Typography variant={`${appType}-body`}>
                {description || 'O imóvel não possui descrição.'}
            </Typography>
        </div>
    )
}