import { AppBar, Toolbar, Typography } from '@mui/material'
import { AtomLogo } from '../../atoms'
import { MolHeaderDrawer, MolHeaderMenus, MolHeaderSearch } from '../../molecules'
import './header.scss'
import { useBreakpoints, useHeaderTitle } from 'src/app/hooks'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

export const OrgHeader = () => {

    const navigate = useNavigate()
    const { appType } = useBreakpoints()
    const { title } = useHeaderTitle()

    const mobileLayout = useMemo(() => (
        <>
            <AtomLogo 
                type={title ? 'colorfulShort' : 'colorful'}
                onClick={() => navigate('/home')}
            />
            <div id='spacer'></div>
            { title && (
                <Typography variant='mobile-h5' id='header-title'>
                    { title }
                </Typography>
            )}
            <div id='spacer'></div>
            <MolHeaderSearch />
            <MolHeaderDrawer />
        </>
    ), [title])

    const desktopLayout = useMemo(() => (
        <>
            <AtomLogo type='colorful' onClick={() => navigate('/home')} />
            <div id='spacer'></div>
            <MolHeaderMenus />
            <MolHeaderSearch />
        </>
    ), [])

    return (
        <AppBar 
            id='org-header' 
            position='sticky'
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <Toolbar className='box'>
                { appType === 'mobile' ? mobileLayout : desktopLayout }
            </Toolbar>
        </AppBar>
    )
}