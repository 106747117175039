import { FC, PropsWithChildren, useMemo } from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { useBreakpoints } from 'src/app/hooks'
import { AtomScrollMenuArrow, AtomScrollMenuFooter } from '../../atoms'
import './scroll-menu.scss'

type MolScrollMenuProps = {
    arrowType?: 'white' | 'blue' | 'transparent'
    arrowPlacement?: 'side' | 'bottom'
    arrowLabel?: string
}

export const MolScrollMenu: FC<PropsWithChildren<MolScrollMenuProps>> = ({ 
    children, 
    arrowType,
    arrowPlacement = 'side',
    arrowLabel
}) => {
    const { appType } = useBreakpoints()

    const shouldShowSideArrows = useMemo(() => (
        appType === 'desktop' && arrowPlacement === 'side'
    ), [appType, arrowPlacement])

    const shouldShowFooter = useMemo(() => (
        appType === 'desktop' && arrowPlacement === 'bottom'
    ), [appType, arrowPlacement])

    return (
        <ScrollMenu
            LeftArrow={shouldShowSideArrows ? <AtomScrollMenuArrow orientation='left' type={arrowType} /> : undefined}
            RightArrow={shouldShowSideArrows ? <AtomScrollMenuArrow orientation='right' type={arrowType} /> : undefined}
            Footer={shouldShowFooter ? <AtomScrollMenuFooter nextLabel={arrowLabel} /> : undefined}
            itemClassName='scroll-item'
            wrapperClassName={arrowPlacement}
        >
            { children && children as any }
        </ScrollMenu>
    )
}