import { FC, PropsWithChildren } from 'react'
import { Yup } from 'src/utils'
import { SchemaContext } from '../contexts'

interface PropTypes {
    schema: Yup.SchemaObjectDescription | undefined
}

export const SchemaProvider: FC<PropsWithChildren<PropTypes>> = ({
    schema,
    children,
}) => (
    <SchemaContext.Provider value={schema}>
        {children}
    </SchemaContext.Provider>
)