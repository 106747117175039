import { Tooltip, Typography } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useBreakpoints } from 'src/app/hooks'
import { humanizeAddress } from 'src/utils'
import { RealEstate } from 'src/types'
import './real-estate-address.scss'

type RealEstateAddressProps = {
    extraClassName?: string
    showLocationLink?: boolean
    data: RealEstate
}

export const MolRealEstateAddress = ({ 
    data: { 
        address 
    }, 
    extraClassName,
    showLocationLink = true
}: RealEstateAddressProps) => {
    const { appType } = useBreakpoints()

    const hasMap = useMemo(() => showLocationLink && Boolean(address.location), [showLocationLink, address])

    const goToMap = useCallback(() => {
        const map = document.querySelector('#map')
        map?.scrollIntoView({ behavior: 'smooth' })
    }, [])

    const addressComponent = useMemo(() => (
        <div className='container' {...hasMap && { onClick: goToMap }}>
            <i className='fi fi-sr-thumbtack'></i>
            <Typography variant={`${appType}-miniText`} {...hasMap && { sx: { textDecoration: 'underline' } }}>
                { humanizeAddress(address) }
            </Typography>
        </div>
    ), [address, hasMap])

    return (
        <div className={`mol-real-estate-address ${hasMap ? 'clickable' : ''} ${extraClassName || ''}`}>
            { hasMap && (
                <Tooltip arrow title='Ver no mapa' placement='top'>
                    { addressComponent }
                </Tooltip>
            )}
            { !hasMap && addressComponent}
        </div>
    )
}