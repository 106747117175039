import { Address } from 'src/types'
import { Axios } from 'src/utils'

class AddressService {
    private readonly endpoint = '/address'

    async cities() {
        const { data } = await Axios.get<Address[]>(`${this.endpoint}/cities`)
        return data
    }

    async neighborhoods(city: string) {
        const { data } = await Axios.get<Address[]>(`${this.endpoint}/neighborhoods/${city}`)
        return data
    }
}

export default new AddressService()