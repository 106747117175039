import { Paginated, PricesPerMeter, RealEstate } from 'src/types'
import { Axios, clearObject } from 'src/utils'

type FilterParams = {
    city?: string
    neighborhood?: string
    roomsQty?: string[]
    status?: string[]
    priceMin?: number
    priceMax?: number
    constructionCompanyId?: number
    rowsPerPage?: number
    page?: number
}

class RealEstateService {
    private readonly endpoint = '/real-estate'

    async list(params?: FilterParams) {
        const { data } = await Axios.get<Paginated<RealEstate[]>>(
            this.endpoint, 
            { params: clearObject(params) }
        )
        return data
    }

    async find(id: string) {
        const { data } = await Axios.get<RealEstate>(
            `${this.endpoint}/${id}`, 
        )
        return data
    }

    async findPriceCalculator(id: string | number) {
        const { data } = await Axios.get<PricesPerMeter>(
            `${this.endpoint}/${id}/price-comparator`, 
        )
        return data
    }
}

export default new RealEstateService()
