import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'

export const MolFooterAddress = () => {
    const { appType } = useBreakpoints()

    return (
        <div id='mol-footer-address'>
            <Typography 
                variant={`${appType}-miniText`}
                color='white'
            >
                Av. 7 de Setembro, 415 - sala 401 - Vila Rodrigues, Passo Fundo - RS, 99010-120
            </Typography>
        </div>
    )
}