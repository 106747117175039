import { SelectOptions } from 'src/app/components'
import { RealEstateStatus } from 'src/enums'

export const roomsQtyOptions: SelectOptions[] = [
    { label: '1', value: 'one' },
    { label: '2', value: 'two' },
    { label: '3', value: 'three' },
    { label: '4+', value: 'fourOrMore' },
]

export const statusOptions: SelectOptions<RealEstateStatus>[] = [
    {label: RealEstateStatus.preLaunch, value: 'Pré-lançamento'},
    {label: RealEstateStatus.launch, value: 'Lançamento'},
    {label: RealEstateStatus.underConstruction, value: 'Em construção'},
    {label: RealEstateStatus.readyToLive, value: 'Pronto para morar'},
    {label: RealEstateStatus.preOwnedAndStock, value: 'Seminovos e Repasses'},
]
