import { Button, CircularProgress } from '@mui/material'
import { AtomButtonProps } from './types'
import './button.scss'

export const AtomButton = ({
    loading,
    variant = 'primary',
    ...buttonProps
}: AtomButtonProps) => (
    <Button
        className={`atom-button ${variant}`}
        {...buttonProps}
        { ...loading && {
            disabled: true,
            children: <CircularProgress size='24.5px' />
        }}
    />
)