import { Typography } from '@mui/material'
import './construction-company-tag.scss'
import { useBreakpoints } from 'src/app/hooks'

type MolConstructionCompanyTagProps = {
    icon: string
    title: string
    text: string
}

export const MolConstructionCompanyTag = ({
    icon,
    title,
    text
}: MolConstructionCompanyTagProps) => {
    const { appType } = useBreakpoints()

    return (
        <div className='mol-construction-company-tag'>
            <i className={`fi ${icon}`}></i>
            <Typography variant={`${appType}-h5`}>
                { title }
            </Typography>
            <Typography variant={`${appType}-miniText`} className='text'>
                { text }
            </Typography>
        </div>
    )
}