import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { FormProps, FormType } from './types'
import { isEqual } from 'lodash'
import { SchemaProvider } from 'src/app/providers'
import { MolTextField } from '../../molecules/text-field/text-field'
import { MolCheckboxField } from '../../molecules/checkbox-field/checkbox-field'
import { MolSelectField } from '../../molecules/select-field/select-field'
import { MolAutocompleteField } from '../../molecules/autocomplete-field/autocomplete-field'

const FormComponent = <T extends Record<string, any>>({
    children,
    formObject,
    ...formProps
}: PropsWithChildren<FormProps<T>>) => {
    const { form, context, schema } = formObject

    const getSchemaDescription = useCallback(
        (value: T) => schema.describe({ value, context }),
        [context, schema],
    )

    const [schemaDescription, setSchemaDescription] = useState(() =>
        getSchemaDescription(form.getValues()),
    )

    useEffect(() => {
        const updateSchemaDescribe = () => {
            setSchemaDescription((prevState) => {
                const newDescription = getSchemaDescription(form.getValues())

                return isEqual(newDescription, prevState) ? prevState : newDescription
            })
        }

        updateSchemaDescribe()
        const { unsubscribe } = form.watch(updateSchemaDescribe)

        return () => {
            unsubscribe()
        }
    }, [form, getSchemaDescription])

    return (
        <SchemaProvider schema={schemaDescription}>
            <FormProvider {...form}>
                <div {...formProps }>
                    { children }
                </div>
            </FormProvider>
        </SchemaProvider>
    )
}

FormComponent.TextField = MolTextField
FormComponent.Checkbox = MolCheckboxField
FormComponent.Select = MolSelectField
FormComponent.Autocomplete = MolAutocompleteField

export const TemForm = <T extends AnyRecord>(): FormType<T> => FormComponent
