import { FC } from 'react'
import { useBreakpoints, useForm } from 'src/app/hooks'
import { defaultValues, Form, validationSchema } from './form-controller'
import { Typography } from '@mui/material'
import { RealEstateEngageForm } from './types'
import { AtomButton } from '../../atoms'
import './real-estate-engage-form.scss'

type MolRealEstateEngageFormProps = {
    onSubmit: (value: RealEstateEngageForm) => void
}

export const MolRealEstateEngageForm: FC<MolRealEstateEngageFormProps> = ({ onSubmit }) => {

    const { appType } = useBreakpoints()

    const formObject = useForm<RealEstateEngageForm>({
        defaultValues,
        validationSchema,
    })

    const {
        handleSubmit,
        formState: {
            isValid
        }
    } = formObject.form

    return (
        <div className='mol-real-estate-engage-form'>
            <Typography variant={`${appType}-h3`} color='primary'>
                Saiba mais
            </Typography>

            <Form formObject={formObject} id='form'>
                <Typography variant={`${appType}-h5`}>
                    Informe seus dados
                </Typography>

                <Form.TextField name='name' label='Nome completo' autoFocus />
                <Form.TextField name='email' label='E-mail' />
                <Form.TextField name='number' label='Número de telefone' placeholder='(  ) _____-____' />

                <Typography variant={`${appType}-body`}>
                    <Typography variant={`${appType}-body`} fontWeight='bold'>
                        Importante:
                    </Typography>
                    entraremos em contato com você em até 24h.
                </Typography>

                <AtomButton disabled={!isValid} onClick={handleSubmit(onSubmit)}>
                    Enviar
                </AtomButton>
            </Form>
        </div>
    )

}