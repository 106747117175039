import { ReactNode, useCallback, useEffect, useState } from 'react'
import { HeaderTitleContext } from '../contexts'
import { useLocation } from 'react-router-dom'

type Props = {
    children: ReactNode
}

export const HeaderTitleProvider = ({ children }: Props) => {

    const location = useLocation()

    const [title, setTitle] = useState<string | null>(null)

    const updateTitle = useCallback((newTitle: string | null) => {
        setTitle(newTitle)
    }, [setTitle])

    useEffect(() => {
        if(location && !location.pathname.includes('/real-estate/'))
            updateTitle(null)
    }, [location])

    return (
        <HeaderTitleContext.Provider value={{ title, updateTitle }}>
            { children }
        </HeaderTitleContext.Provider>
    )
}