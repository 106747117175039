import { useAsyncFetch, useBreakpoints } from 'src/app/hooks'
import { Paginated, RealEstate } from 'src/types'
import { Axios } from 'src/utils'
import { AtomLoader, AtomUnderlinedText, MolRealEstateCard } from 'src/app/components'
import { Typography } from '@mui/material'
import '../result-styles.scss'
import { RealEstateService } from 'src/services'

export const RealEstatesOtherResults = () => {

    const { appType } = useBreakpoints()

    const { data: result, loading } = useAsyncFetch({
        callback: () => RealEstateService.list(),
        errorMessage: 'Ocorreu um erro ao carregar os empreendimentos'
    }, [])

    return (
        <div className='real-estates-result box'>
            <AtomUnderlinedText>
                <Typography variant={`${appType}-h3`}>
                    Alguns resultados semelhantes
                </Typography>
            </AtomUnderlinedText>

            { loading && <AtomLoader /> }

            { result && result.data.length > 0 && (
                <div className='content'>
                    { result.data.map(realEstate => (
                        <MolRealEstateCard key={realEstate.id} data={realEstate} />
                    )) }
                </div>
            )}
        </div>
    )
}