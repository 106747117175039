import { useAsyncFetch, useBreakpoints } from 'src/app/hooks'
import { RealEstateService } from 'src/services'
import { AtomUnderlinedText } from '../../atoms'
import { Typography } from '@mui/material'
import { MolRealEstateMiniCard, MolScrollMenu } from '../../molecules'
import './best-opportunities.scss'

type OrgBestOpportunitiesProps = {
    desktopBgColor?: 'white' | 'gray'
}

export const OrgBestOpportunities = ({ desktopBgColor }: OrgBestOpportunitiesProps) => {
    const { appType, dimensions } = useBreakpoints()

    const { data: realEstates } = useAsyncFetch({
        callback: () => RealEstateService.list(),
        formatter: (result) => result.data, 
        errorMessage: 'Ocorreu um erro ao carregar os lançamentos'
    }, [])

    return (
        <div className={`org-best-opportunities ${desktopBgColor || ''}`}>
            <div className='content box'>
                <AtomUnderlinedText 
                    showUnderline={appType === 'desktop'} 
                    type={appType === 'mobile' ? 'solid' : 'gradient'}
                    className='title'
                >
                    <i className='fi fi-sr-usd-circle'></i>
                    <Typography variant={`${appType}-h3`} {...appType === 'mobile' && { color: 'white' }}>
                        Melhores oportunidades
                    </Typography>
                </AtomUnderlinedText>

                {realEstates && (
                    <MolScrollMenu>
                        {realEstates && realEstates.map((realEstate, index) => (
                            <MolRealEstateMiniCard 
                                itemId={index} 
                                key={realEstate.id} 
                                data={realEstate}
                            />
                        ))}
                    </MolScrollMenu>
                )}
            </div>
        </div>
    )
}