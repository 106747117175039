import { Rating, Typography, Avatar } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import './rating.scss'
import { Review } from 'src/types/review'


export const MolRating = ({ review }: {review: Review}) => {
    const { appType } = useBreakpoints()

    const { authorName, authorProfilePictureUrl, description, rating, time } = review
    return (
        <div className='rating-card' >
            <div className='user-profile'>
                <Avatar src={authorProfilePictureUrl}  alt={authorName} className='avatar' />

                <div className='info'>
                    <Typography variant={`${appType}-h6`} fontWeight="bold">
                        {authorName}
                    </Typography>

                    <div className='rating'>
                        <Rating
                            value={rating}
                            precision={0.5}
                            readOnly
                        />

                        <Typography className='review-date'>
                            {time}
                        </Typography>
                    </div>
                </div>
            </div>
            <Typography variant={`${appType}-body`} className='review-text'>
                {description}
            </Typography>
        </div>
    )
}