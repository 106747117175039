import { AtomButton, AtomDivider, OrgFaq, OrgJoinUs } from 'src/app/components'
import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import './styles.scss'

export const Help = () => {

    const { appType } = useBreakpoints()

    return (
        <div className='faq-page'>
            <div className='content box'>
                <OrgFaq showSubtitle={appType === 'mobile' ? true : false}/>

                { appType === 'mobile' && (
                    <AtomDivider />
                )}

                { appType === 'desktop' && (
                    <>
                        <div className='spacer'></div>
                        <div className='spacer'></div>
                    </>
                )}
                
                <div className='contact-us'>
                    <Typography variant='mobile-h3'>Entre em contato</Typography>

                    <div className='buttons'>
                        <AtomButton variant='contained'>
                            <i className="fi fi-brands-whatsapp"></i> <p>Converse via <Typography variant='mobile-button'>Whatsapp</Typography></p>
                        </AtomButton>

                        <AtomButton variant='contained'>
                            <i className="fi fi-rr-envelope"></i> <p>Envie um <Typography variant='mobile-button'>E-mail</Typography></p>
                        </AtomButton>
                    </div>
                </div>

                { appType === 'desktop' && (
                    <div className='spacer'></div>
                )}
            </div>

            { appType === 'mobile' && (
                <AtomDivider />
            )}

            <div className='join-us'>
                <OrgJoinUs visualizeAllButton={true}/>
            </div>
        </div>
    )
}