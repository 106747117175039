import { Map } from '@vis.gl/react-google-maps'
import { FC, useCallback, useEffect, useState } from 'react'
import { MolMapPin } from 'src/app/components'
import { useBreakpoints } from 'src/app/hooks'
import { RealEstate } from 'src/types'
import { env } from 'src/utils'

type ControlledRealEstate = {
    data: RealEstate
    flag: boolean
}

type RealEstatesMapProps = {
    data: RealEstate[]
}

export const RealEstatesMap: FC<RealEstatesMapProps> = ({ data }) => {
    const { appType } = useBreakpoints()

    const [controlledData, setControlledData] = useState<ControlledRealEstate[]>([])

    const toggleItemFlag = useCallback((index: number, value: boolean) => {
        setControlledData(currentStateData => 
            currentStateData.map((item, itemIndex) => ({
                data: item.data,
                flag: index === itemIndex ? value : false
            }))
        )
    }, [controlledData, setControlledData])

    const mapClickHandler = useCallback(() => {
        setControlledData(value => (
            value.map(item => ({
                data: item.data,
                flag: false
            }))
        ))
    }, [controlledData, setControlledData])

    useEffect(() => {
        const mapElement = document.querySelector('div.map-container')

        if(mapElement) {
            mapElement.addEventListener('click', mapClickHandler)
            return () => mapElement.removeEventListener('click', mapClickHandler)
        }
    }, [])

    useEffect(() => {
        const newControlledData: ControlledRealEstate[] = data.map(item => ({
            data: item,
            flag: false
        }))

        setControlledData(newControlledData)
    }, [data])

    return (
        <Map
            mapId={env.googleCloud.mapsMapId} 
            defaultZoom={13} 
            className='map-container'
            defaultCenter={{
                lat: -28.262694, 
                lng: -52.410823
            }}
            disableDefaultUI={true}
        >
            {controlledData.map(({ data, flag }, index) => (
                <MolMapPin
                    key={data.id} 
                    realEstate={data}
                    infoWindowFlag={flag}
                    closeInfoWindow={() => toggleItemFlag(index, false)}
                    openInfoWindow={() => toggleItemFlag(index, true)}
                />
            ))}
        </Map>
    )
}