import { useAsyncFetch } from 'src/app/hooks'
import { RealEstateService } from 'src/services'
import { Typography } from '@mui/material'
import { MolRealEstateMiniCard, MolScrollMenu } from 'src/app/components'
import { RealEstate } from 'src/types'
import './construction-company-others.scss'

type OrgConstructionCompanyOthersProps = {
    data: RealEstate
}

export const OrgConstructionCompanyOthers = ({ data: { id, constructionCompanyId } }: OrgConstructionCompanyOthersProps) => {
    const { data: result } = useAsyncFetch({
        callback: () => RealEstateService.list({ constructionCompanyId }),
        formatter: ({ data, total }) => ({
            total,
            data: data.filter(item => item.id !== id)
        }),
        errorMessage: 'Ocorreu um erro ao carregar mais oportunidades da construtora',
    }, [constructionCompanyId])

    if(!result || result.data.length === 0) return null

    return (
        <div className='org-construction-company-others'>
            <Typography variant='mobile-h3' className='box'>
                Mais oportunidades da construtora
            </Typography>
            <MolScrollMenu>
                { result.data.map(realEstate => (
                    <MolRealEstateMiniCard
                        key={realEstate.id}
                        itemId={realEstate.id}
                        data={realEstate}
                        showOldPrice={false}
                        highlightedPrice={false}
                    />
                )) }
            </MolScrollMenu>
        </div>
    )
}