import { Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useBreakpoints } from 'src/app/hooks'
import { AtomButton } from 'src/app/components'
import { BuildingPlanDetails } from './components/building-plan-details'
import { useState } from 'react'
import ImageZoom from 'react-image-zooom'
import './styles.scss'

export const BuildingPlan = () => {
    const [flag, setFlag] = useState<boolean>(false)

    const open = () => setFlag(true)
    const close = () => setFlag(false)

    const { appType } = useBreakpoints()
    const location = useLocation()
    const { realEstateName, paymentConditions, price, planUrl, name } = location.state || {}

    return (
        <div className='building-plan-page'>
            <div className='header box'>
                <i className="fi fi-bs-angle-left"></i>
                <Typography variant={`${appType}-h3`}>{realEstateName}</Typography>
            </div>
            <div className='label box'>
                <Typography variant={`${appType}-h5`} className='name'>
                    { name }
                </Typography>
            </div>
            <div className='content'>
                <ImageZoom src={planUrl} alt={name}  />
                <div className='details-button'>
                    <AtomButton  variant='secondary' onClick={() => open()}> Detalhes </AtomButton>
                </div>
            </div>

            <BuildingPlanDetails close={close} flag={flag} paymentConditions={paymentConditions} price={price}/>
        </div>
    )
}