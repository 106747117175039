import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { TemBaseLayout, TemBaseLayoutWithoutFooter } from '../components'
import { 
    ConstructionCompanies,
    ConstructionCompany,
    Error,
    Help,
    Home,
    RealEstate,
    RealEstates,
    Gallery,
    BuildingPlan
} from '../pages'

const routes = createBrowserRouter([
    {
        element: <TemBaseLayout />,
        children: [{
            errorElement: <Error />,
            children: [
                { path: '/construction-company', element: <ConstructionCompanies /> },
                { path: '/construction-company/:id', element: <ConstructionCompany /> },
                { path: '/help', element: <Help /> },
                { path: '/', element: <Home /> },
                { path: '/home', element: <Home /> },
                { path: '/real-estate/:id', element: <RealEstate /> },
                { path: '/real-estate', element: <RealEstates /> },

                { path: '*', loader: () => { throw new Response('Página não encontrada', { status: 404 }) }}
            ]
        }],
    },
    {
        element: <TemBaseLayoutWithoutFooter />,
        children: [{
            errorElement: <Error />,
            children: [
                { path: '/real-estate/:id/gallery', element: <Gallery /> },
                { path: '/real-estate/:id/building-plan/:buildingPlanId', element: <BuildingPlan /> },
            ]
        }],
        
    }
])



export const Router = () => <RouterProvider router={routes}/>