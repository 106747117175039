import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConstructionCompany } from 'src/types'
import { AtomAvatar, AtomLogo } from '../../atoms'
import { Rating, Typography, useTheme } from '@mui/material'
import { MolConstructionCompanyTag } from '../../molecules'
import { env, formatNumber, humanizeAddress } from 'src/utils'
import { useBreakpoints } from 'src/app/hooks'
import { AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps'
import { MolRatings } from '../../molecules/ratings/ratings'
import './construction-company-info.scss'

type OrgConstructionCompanyInfoProps = {
    data: ConstructionCompany
    contentClassName?: string
    btnRedirect?: 'profile' | 'reviews'
    showMap?: boolean
    showAvatar?: boolean
}

export const OrgConstructionCompanyInfo = ({ 
    data: constructionCompany,
    contentClassName,
    btnRedirect = 'profile',
    showAvatar = true,
    showMap = false
}: OrgConstructionCompanyInfoProps) => {
    
    const { appType } = useBreakpoints()
    const navigate = useNavigate()
    const theme = useTheme()

    const goToRedirect = useCallback(() => {
        if(btnRedirect === 'profile') {
            navigate(`/construction-company/${constructionCompany?.id}`)
        } else {
            console.warn('Não implementado ainda')
        }
    }, [btnRedirect])

    const position: google.maps.LatLngLiteral = useMemo(() => ({
        lat: constructionCompany.address.location?.coordinates[0] || 0,
        lng: constructionCompany.address.location?.coordinates[1] || 0,
    }), [constructionCompany])

    return (
        <div className='org-construction-company-info'>
            <div className={`head ${contentClassName || ''}`}>
                {showAvatar && (
                    <AtomAvatar
                        size={appType === 'desktop' ? 'medium' : 'small'}
                        src={constructionCompany?.profilePictureUrl}
                    />
                )}
                <div className='title'>
                    <Typography variant={`${appType}-h3`}>
                        Sobre a Construtora {constructionCompany?.name}
                    </Typography>
                    <div className='rating'>
                        {constructionCompany.rating && (
                            <>
                                <Typography variant={`${appType}-h6`}>
                                    {formatNumber(constructionCompany.rating)}
                                </Typography>
                                <Rating value={constructionCompany.rating} readOnly precision={0.1} />
                            </>
                        )}
                        <Typography variant={`${appType}-miniText`} className='redirect' onClick={goToRedirect}>
                            { btnRedirect === 'profile' 
                                ? 'Ir para o perfil' 
                                : <MolRatings constructionCompany={constructionCompany}></MolRatings>
                            }
                        </Typography>           
                    </div>
                </div>
            </div>
            {constructionCompany?.description && (
                <Typography variant={`${appType}-body`} className={contentClassName || ''}>
                    {constructionCompany?.description}
                </Typography>
            )}
            <div className='tags'>
                <MolConstructionCompanyTag
                    icon='fi-rs-house-building'
                    title='36'
                    text='empreendimentos cadastrados'
                />
                <MolConstructionCompanyTag
                    icon='fi-rr-hourglass-end'
                    title='+ de 45'
                    text='anos no mercado'
                />
                <MolConstructionCompanyTag
                    icon='fi-rr-key'
                    title='+ de 70'
                    text='empreendimentos entregues'
                />
                <MolConstructionCompanyTag
                    icon='fi-rs-build'
                    title='+ de 350m²'
                    text='de área construída'
                />
            </div>
            <Typography variant={`${appType}-miniText`} className={`address ${contentClassName || ''}`}>
                { humanizeAddress(constructionCompany?.address) }
            </Typography>

            {showMap && (
                <Map
                    mapId={env.googleCloud.mapsMapId} 
                    defaultCenter={position} 
                    defaultZoom={appType === 'mobile' ? 15 : 16} 
                    className='map-container'
                    disableDefaultUI={true}
                >
                    <AdvancedMarker position={position}>
                        <Pin 
                            background={theme.palette.primary.main} 
                            borderColor={theme.palette.primary.main} 
                            scale={1.4}
                        >
                            <AtomLogo type='whiteShort' />
                        </Pin>
                    </AdvancedMarker>
                </Map>
            )}
        </div>
    )
}