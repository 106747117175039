import { PropsWithChildren } from 'react'
import './underlined-text.scss'

type AtomUnderlinedTextProps = {
    type?: 'primary' | 'solid' | 'gradient' | 'gray'
    showUnderline?: boolean
    className?: string
}

export const AtomUnderlinedText = ({
    type = 'solid', 
    showUnderline = true,
    className = '',
    children
}: PropsWithChildren<AtomUnderlinedTextProps>) => (
    <div className='atom-underlined-text'>
        <div className={className}>
            { children }
        </div>
        {showUnderline && (
            <div className={`underline ${type}`}></div>
        )}
    </div>
)