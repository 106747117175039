import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import { RealEstate } from 'src/types'
import { MolRealEstateStatus } from '../../molecules/real-estate-status/real-estate-status'
import './real-estate-statuses.scss'
import { Fragment, LegacyRef, useEffect, useMemo, useRef } from 'react'
import { IconMapKey, IconStatus } from './types'
import { statusOptions } from 'src/utils'
import { iconsMap } from './utils'
import { RealEstateStatus } from 'src/enums'

type MolRealEstateStatusesProps = {
    data: RealEstate
    className?: string
}

export const OrgRealEstateStatuses = ({
    data: {
        status,
        releaseDate,
    },
    className
}: MolRealEstateStatusesProps) => {
    const { appType } = useBreakpoints()

    const iconRef = useRef<HTMLElement>(null)
    const customTooltipRef = useRef<HTMLDivElement>(null)

    const readyToLiveLabel = useMemo(() => (
        appType === 'mobile' ? `Pronto (Previsão para ${releaseDate})` : 'Pronto'
    ), [appType, releaseDate])

    const statuses: IconStatus[] = useMemo(() => (
        statusOptions
            .filter(({ value }) => value !== RealEstateStatus.preOwnedAndStock)
            .map(({ label, value }) => ({
                icon: iconsMap[label as IconMapKey],
                label: value == RealEstateStatus.readyToLive 
                    ? readyToLiveLabel 
                    : label,
                active: label === status
            }))
    ), [status, readyToLiveLabel])

    useEffect(() => {
        if(
            !statuses || 
            appType === 'mobile' ||
            !iconRef.current ||
            !customTooltipRef.current
        ) return

        const { offsetLeft, offsetWidth, offsetHeight } = iconRef.current
        const { width } = customTooltipRef.current.getBoundingClientRect()
        
        customTooltipRef.current.style.left = `calc(${offsetLeft}px - (${width}px / 2) + (${offsetWidth}px / 2))`
        customTooltipRef.current.style.top = `calc(${offsetHeight}px + 15px)`
    }, [statuses, appType, iconRef.current, customTooltipRef.current])

    return (
        <div className={`org-real-estate-statuses ${className || ''}`}>
            <Typography variant={`${appType}-h3`}>
                Status do empreendimento
            </Typography>
            <div className='container'>
                {statuses && statuses.map(((statusProps, index) => (
                    <Fragment key={statusProps.label}>
                        <MolRealEstateStatus
                            {...statusProps}
                            {...index !== statuses.length && { ref: iconRef }}
                        />
                        {index !== statuses.length - 1 && (
                            <div className='divider'/>
                        )}
                    </Fragment>
                )))}
                {releaseDate && appType === 'desktop' && (
                    <div ref={customTooltipRef} className='custom-tooltip'>
                        Previsão para { releaseDate }
                    </div>
                )}
            </div>
        </div>
    )
}