import { FC, useCallback } from 'react'
import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import { AtomTag } from '../../atoms'
import { ConstructionCompany } from 'src/types'
import { RevertState } from 'src/enums'
import { useNavigate } from 'react-router-dom'
import './construction-company-card.scss'

type ConstructionCompanyCardProps = {
    data: ConstructionCompany
};

export const ConstructionCompanyCard: FC<ConstructionCompanyCardProps> = ({ data }) => {

    const {appType} = useBreakpoints()
    const navigate = useNavigate()

    const {
        id,
        bannerUrl,
        profilePictureUrl,
        name,
        summary,
        realEstatesAddresses,
        realEstatesQty
    } = data

    const goToConstructionCompanyPage = useCallback(() => {
        navigate(`/construction-company/${id}`)
    }, [id, navigate])

    return (
        <div className="card-container" onClick={goToConstructionCompanyPage}>
            <div className="banner-container">
                <img src={bannerUrl} />
            </div>

            <div className="content">
                <div className="border-profile">
                    <div className="profile-image">
                        <div style={{ backgroundImage: `url(${profilePictureUrl})` }}  />
                    </div>
                </div>
                
                <div className="construction-company-info">
                    <Typography variant={`${appType}-h5`} className='name'>
                        { name }
                    </Typography>
                    <Typography variant={`${appType}-miniText`} className='summary'>
                        { summary }
                    </Typography>
                </div>
            </div>

            <div className="footer-card"> 
                { realEstatesAddresses && realEstatesAddresses.map(({ id, city, state }) => (
                    <AtomTag key={id} label={`${city}/${RevertState[state]}`} />  
                )) }
                <div className='spacer'></div>
                <div className="profile-button">
                    <div className="icon-and-number">
                        <i className="fi fi-rs-house-building icon-size"></i>
                        <Typography variant={`${appType}-h5`}>
                            {realEstatesQty}
                        </Typography>
                    </div>
                    <Typography variant={`${appType}-miniText`}>
                        empreend.
                    </Typography>
                </div>
            </div>

        </div>
    )
}