import { useCallback, useRef } from 'react'

export const useImmutableCallback = <T extends AnyFunction>(callback: T) => {
    const callbackRef = useRef(callback)
    callbackRef.current = callback

    return useCallback(
        (...args: Parameters<T>): ReturnType<T> => callbackRef.current(...args),
        [],
    )
}
