import { Typography } from '@mui/material'
import { AtomButton, AtomDivider, AtomUnderlinedText, MolRealEstateCard } from 'src/app/components'
import { useAsyncFetch, useBreakpoints } from 'src/app/hooks'
import { RealEstate } from 'src/types'
import { useCallback } from 'react'
import * as lodash from 'lodash'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import { RealEstateStatus } from 'src/enums'
import { RealEstateService } from 'src/services'

type GroupedRealEstates = {
    label: string
    data: RealEstate[]
}

export const HomeList = () => {

    const { appType } = useBreakpoints()

    const navigate = useNavigate()

    const { data: groupedRealEstates } = useAsyncFetch({
        callback: () => RealEstateService.list(),
        formatter: (value) => {
            return lodash
                .chain(value.data)
                .groupBy('address.city')
                .map<GroupedRealEstates>((value, key) => ({ label: key, data: value }))
                .value()
        },
        errorMessage: 'Ocorreu um erro ao carregar os lançamentos'
    }, [])

    const groupNavigate = useCallback((group: GroupedRealEstates) => {
        navigate('/real-estate', { state: { city: group.label } })
    }, [navigate])

    return (
        <div className='home-list box'>
            <AtomUnderlinedText showUnderline={appType === 'desktop'}>
                <Typography variant={`${appType}-h3`}>
                    Empreendimentos
                </Typography>
            </AtomUnderlinedText>

            <div className='content'>
                {groupedRealEstates && groupedRealEstates.map((group, index) => (
                    <>
                        {index > 0 && <AtomDivider />}

                        <div className='items'>
                            {group.data.map((realEstate) => (
                                <MolRealEstateCard key={realEstate.id} data={realEstate} />
                            ))}
                        </div>
                        <AtomButton
                            variant='primary'
                            onClick={() => groupNavigate(group)}
                        >
                            Mais opções em {group.label}
                        </AtomButton>
                    </>
                ))}
            </div>
        </div>
    )
}