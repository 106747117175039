import { Typography } from '@mui/material'
import { MolBuildingPlanCard, MolScrollMenu, OrgRealEstateEngage } from 'src/app/components'
import { RealEstate } from 'src/types'
import { useBreakpoints } from 'src/app/hooks'
import ImageZoom from 'react-image-zooom'
import './real-estate-building-plans.scss'

type OrgRealEstateBuildingPlansProps = {
    data: RealEstate
}

export const OrgRealEstateBuildingPlans = ({ data: { buildingPlans, name, address } }: OrgRealEstateBuildingPlansProps) => {

    const { appType } = useBreakpoints()

    function formatPrice(price: string): string {
        const number = parseFloat(price)
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
        }).format(number)
    }

    if(appType === 'mobile') {
        return (
            <div className='real-estate-building-plans'>
                <div className='box'>
                    <Typography variant={'mobile-h3'} className='title'>
                        Plantas disponíveis
                    </Typography>
                </div>

                <MolScrollMenu>
                    {buildingPlans!.map((buildingPlan, index) => (
                        <MolBuildingPlanCard
                            itemId={index}
                            key={buildingPlan.id}
                            buildingPlan={buildingPlan}
                            realEstateName={`${name} - ${address.name}`}
                        />
                    ))}
                </MolScrollMenu>
            </div>
        )
    }

    if(appType === 'desktop') {
        return (
            <div className='building-plan-desktop'>
                <div className='box'>
                    <div className='header'>
                        <Typography variant='desktop-h3'>Plantas disponíveis</Typography>
                    </div>

                    <MolScrollMenu>
                        {buildingPlans!.map(({ id, name, planUrl, paymentConditions, price }) => (
                            <div className='content' itemID={id.toString()} key={id}>
                                <div className='plan-img'>
                                    <ImageZoom src={planUrl} zoom="200"/>
                                </div>

                                <div className='offset' />

                                <div className='details'>
                                    <div className='label'>
                                        <Typography variant='desktop-h5'>{name}</Typography>
                                    </div>

                                    <div className='payment-conditions'>
                                        <ul>
                                            {paymentConditions.map((paymentCondition: string, index: number) => (
                                                <li key={index}>
                                                    <Typography variant='desktop-body'>
                                                        {paymentCondition}
                                                    </Typography>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className='price'>
                                        <Typography variant='desktop-body'>A partir de</Typography>
                                        <Typography variant='desktop-h3'>
                                            {formatPrice(String(price))}
                                        </Typography>
                                        
                                        <OrgRealEstateEngage/>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </MolScrollMenu>
                </div>
            </div>
        )
    }

    return <></>
}