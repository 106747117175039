import { Link } from 'react-router-dom'
import './footer-links.scss'
import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import { useState } from 'react'
import { AtomModal } from '../../atoms'

export const MolFooterLinks = () => {
    const { appType } = useBreakpoints()

    const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false)
    const [termsOfUse, setTermsOfUse] = useState<boolean>(false)
    const [termsOfService, setTermsOfService] = useState<boolean>(false)

    return (
        <div id='mol-footer-links'>
            <Typography 
                variant={`${appType}-miniText`} 
                className='link' 
                onClick={() => setPrivacyPolicy(true)}
            >
                Política de privacidade
            </Typography>

            <Typography 
                variant={`${appType}-miniText`} 
                className='link' 
                onClick={() => setTermsOfUse(true)}
            >
                Termos de Uso
            </Typography>

            <Typography 
                variant={`${appType}-miniText`} 
                className='link' 
                onClick={() => setTermsOfService(true)}
            >
                Termos de Serviço
            </Typography>

            <AtomModal paperClassName='custom-paper' flag={privacyPolicy} onClose={() => setPrivacyPolicy(false)}>
                <div className='mol-footer-links-text-container'>
                    <Typography variant={`${appType}-h3`} className='title'>
                        Política de Privacidade
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        1. OBJETIVO
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Esta Política de Privacidade tem como objetivo demonstrar o compromisso da Hub House com a privacidade e proteção dos seus dados, além de estabelecer as regras para o tratamento de dados pessoais.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        2. SOBRE OS DADOS COLETADOS
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        {'Coletamos as informações inseridas ativamente por você ("Usuário") no momento do contato ou cadastro, seja pelo preenchimento de formulários ou atendimento online. Além disso, coletamos automaticamente dados enquanto você utiliza nossos serviços.'}
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='subsection'>
                        2.1 Dados Pessoais Fornecidos pelo Usuário
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Coletamos dados pessoais fornecidos ativamente por você, como nome completo, e-mail, telefone, entre outros, sempre que você interagir com nossos serviços. Utilizamos apenas os dados relevantes e necessários para as finalidades declaradas no momento da coleta.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='subsection'>
                        2.2 Dados Coletados Automaticamente
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Coletamos automaticamente informações como características do dispositivo de acesso, navegador, endereço IP, cliques, páginas acessadas e termos de busca. Utilizamos tecnologias como cookies para melhorar sua experiência de navegação com base em seus hábitos e preferências.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        3. COM QUAL FINALIDADE A HUB HOUSE TRATA SEUS DADOS?
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Seus dados são tratados com as seguintes finalidades:<br />
                        - Prover serviços e produtos por meio das nossas plataformas;<br />
                        - Fornecer informações via formulário de contato ou atendimento online;<br />
                        - Manter seus dados atualizados;<br />
                        - Melhorar sua experiência de navegação;<br />
                        - Enviar conteúdos publicitários e informativos mediante sua autorização.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        4. COMPARTILHAMENTO DE DADOS
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        A Hub House poderá compartilhar seus dados nas seguintes situações: <br />
                        - Com autoridades judiciais ou administrativas, conforme exigido por lei; <br />
                        - Com instituições financeiras, para análise de crédito; <br />
                        - Com empresas parceiras e fornecedores que nos auxiliam na prestação dos serviços; <br />
                        - Com corretores de imóveis ou imobiliárias, quando necessário.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        5. SEGURANÇA DOS DADOS
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Adotamos medidas de segurança adequadas para proteger seus dados contra acessos não autorizados e outras situações acidentais ou ilícitas. O acesso às suas informações é restrito a colaboradores autorizados e comprometidos com a privacidade dos dados.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        6. ARMAZENAMENTO DOS DADOS
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Seus dados serão mantidos pelo tempo necessário para cumprir as finalidades para as quais foram coletados ou conforme exigido por lei. Você pode solicitar a exclusão de seus dados a qualquer momento, exceto quando a manutenção for exigida legalmente.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        7. SEUS DIREITOS
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Como titular dos dados, você tem os seguintes direitos: <br />
                        - Confirmação da existência de tratamento de dados; <br />
                        - Acesso aos seus dados pessoais; <br />
                        - Correção de dados incompletos ou desatualizados; <br />
                        - Solicitação de anonimização, bloqueio ou exclusão de dados; <br />
                        - Portabilidade dos dados, mediante solicitação expressa; <br />
                        - Revogação do consentimento.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        8. EXERCÍCIO DOS DIREITOS
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Você pode exercer seus direitos diretamente em nossa plataforma ou entrando em contato pelo e-mail: contato@hubhouse.imb.br. Nos comprometemos a responder suas solicitações no menor tempo possível, respeitando eventuais complexidades do pedido.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        9. UTILIZAÇÃO DE COOKIES
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Utilizamos cookies para otimizar sua experiência em nosso site. Esses cookies armazenam informações como o endereço IP, páginas acessadas e preferências de navegação. Você pode desativar os cookies a qualquer momento, ajustando as configurações do seu navegador, embora isso possa impactar algumas funcionalidades do site.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        10. CONTATO
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Se tiver dúvidas ou preocupações sobre esta Política de Privacidade ou sobre o tratamento de seus dados pessoais, entre em contato conosco pelo e-mail: contato@hubhouse.imb.br.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        11. LEGISLAÇÃO APLICÁVEL
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Esta Política foi elaborada em conformidade com a Lei Geral de Proteção de Dados Pessoais (LGPD - Lei nº 13.709/2018), Marco Civil da Internet (Lei nº 12.965/2014) e outras legislações aplicáveis.
                    </Typography>
                </div>
            </AtomModal>

            <AtomModal paperClassName='custom-paper' flag={termsOfUse} onClose={() => setTermsOfUse(false)}>
                <div className='mol-footer-links-text-container'>
                    <Typography variant={`${appType}-h3`} className='title'>
                        Termos de Uso
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Bem-vindo à Hub House! Agradecemos por utilizar nossos serviços. Estes Termos de Uso descrevem as condições aplicáveis ao uso do nosso site e serviços, disponíveis no endereço <a href="https://hubhouse.imb.br/">https://hubhouse.imb.br/</a>. Ao acessar ou utilizar nosso site, você está concordando com estes Termos de Uso. Leia-os com atenção.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        1. DEFINIÇÕES
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Para fins destes Termos de Uso, aplicam-se as seguintes definições: <br />
                        - Termos de Uso: conjunto de regras contidas neste documento para utilização do site Hub House; <br />
                        - Hub House: empresa responsável pelo conteúdo e serviços disponibilizados através do site <a href="https://hubhouse.imb.br/">https://hubhouse.imb.br/</a>; <br />
                        - Usuário: qualquer pessoa que acesse o site da Hub House ou utilize seus serviços; <br />
                        - Parceiros: construtoras, incorporadoras e demais fornecedores cujos produtos e serviços são divulgados ou oferecidos através do site Hub House.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        2. CONDIÇÕES GERAIS DE USO
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        O acesso ao site Hub House é oferecido para uso pessoal e não comercial. Ao utilizar nossos serviços, você concorda em não: <br />
                        - Modificar, copiar, distribuir, exibir, reproduzir, licenciar ou vender qualquer conteúdo do site sem autorização expressa da Hub House; <br />
                        - Utilizar o site para qualquer fim que seja contrário à legislação aplicável; <br />
                        - Enviar informações falsas ou de terceiros sem autorização; <br />
                        - Utilizar qualquer tipo de código malicioso, vírus ou práticas que possam danificar o site ou prejudicar outros usuários.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        3. PRIVACIDADE E PROTEÇÃO DE DADOS
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        A Hub House valoriza a privacidade de seus usuários. As informações fornecidas por meio do site serão tratadas de acordo com nossa Política de Privacidade, disponível no site, que descreve como coletamos, utilizamos e protegemos seus dados.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        4. RESPONSABILIDADE DA HUB HOUSE
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        A Hub House atua como um intermediário para a divulgação de produtos e serviços imobiliários oferecidos por parceiros, como construtoras e incorporadoras. O site serve como uma plataforma de apresentação desses imóveis, não participando de negociações ou transações entre o usuário e os parceiros.
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        A Hub House não garante a exatidão ou a disponibilidade de preços e condições dos imóveis listados no site, nem a efetivação de qualquer negócio. Recomendamos que o usuário confirme todas as informações diretamente com a construtora ou incorporadora responsável.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        5. LIMITAÇÕES DE RESPONSABILIDADE
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        A Hub House não se responsabiliza por: <br />
                        - Qualquer imprecisão nas informações disponibilizadas no site, incluindo disponibilidade de imóveis, preços ou condições; <br />
                        - Erros cometidos por parceiros em relação às informações fornecidas; <br />
                        - Problemas técnicos que possam ocorrer no site e impactar a navegação ou o acesso às informações.
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        A Hub House também não se responsabiliza por danos diretos ou indiretos resultantes da utilização do site ou pela impossibilidade de acessá-lo, incluindo perdas financeiras, perda de dados ou interrupções de serviço.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        6. LINKS PARA TERCEIROS
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        O site da Hub House pode conter links para sites de terceiros, como páginas de construtoras, incorporadoras ou outros parceiros. A Hub House não controla e não se responsabiliza pelo conteúdo, práticas de privacidade ou serviços oferecidos por esses sites. O uso de links para terceiros é de total responsabilidade do usuário.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        7. PROPRIEDADE INTELECTUAL
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Todo o conteúdo disponível no site da Hub House, incluindo textos, imagens, gráficos, vídeos e logotipos, é protegido por direitos de propriedade intelectual. O uso indevido de qualquer material sem a devida autorização pode resultar em medidas legais.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        8. ENCERRAMENTO DO ACESSO
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        A Hub House se reserva o direito de encerrar ou suspender o acesso ao site a qualquer momento, sem aviso prévio, caso identifique qualquer violação destes Termos de Uso ou outras atividades que comprometam a integridade do site.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        9. MODIFICAÇÕES NOS TERMOS DE USO
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        A Hub House pode modificar estes Termos de Uso a qualquer momento, sem aviso prévio. Tais modificações entrarão em vigor a partir de sua publicação no site. Recomendamos que o usuário revise periodicamente esta página para se manter atualizado.
                    </Typography>
                    <Typography variant={`${appType}-h5`} className='section'>
                        10. LEI APLICÁVEL
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Estes Termos de Uso são regidos pelas leis da República Federativa do Brasil. Fica eleito o foro da Comarca de Passo Fundo para dirimir quaisquer questões relativas a este documento.
                    </Typography>
                </div>
            </AtomModal>

            <AtomModal paperClassName='custom-paper' flag={termsOfService} onClose={() => setTermsOfService(false)}>
                <div className='mol-footer-links-text-container'>
                    <Typography variant={`${appType}-h3`} className='title'>
                        Termos de Serviço
                    </Typography>
                    <Typography variant={`${appType}-body`} className='text'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae convallis purus. Nunc libero lectus, pretium ac aliquam sed, mollis at magna. Nullam porta tellus et tortor tempor blandit. Nullam et quam ut libero vestibulum pharetra. Nullam sed tellus dolor. Aliquam aliquam scelerisque vulputate. Morbi laoreet gravida varius. Integer vehicula malesuada urna, eu vehicula purus interdum et. Vestibulum odio risus, hendrerit pretium lectus quis, sollicitudin sagittis ex.
                    </Typography>
                </div>
            </AtomModal>
        </div>
    )
}