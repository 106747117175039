import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AtomButton, AtomLoader, AtomPlaceholder, MolRealEstateCard } from 'src/app/components'
import { useAsyncFetch, useBreakpoints } from 'src/app/hooks'
import { RealEstateFormData } from 'src/types'
import { RealEstateService } from 'src/services'
import { RealEstatesMap } from '../map'
import '../result-styles.scss'

const rowsPerPage = 6

type Props = {
    filters: RealEstateFormData | undefined
    onShowOtherResults: (value: boolean) => void
}

export const RealEstatesResult = ({ filters, onShowOtherResults }: Props) => {

    const { appType } = useBreakpoints()

    const navigate = useNavigate()

    const [total, setTotal] = useState<number>(0)
    const [page, setPage] = useState<number>(1)

    const [viewMode, setViewMode] = useState<string | null>('cards')

    const { data: result, loading } = useAsyncFetch({
        callback: () => {
            if(!filters) return

            return RealEstateService.list({
                ...filters,
                page: page - 1,
                rowsPerPage
            })
        },
        errorMessage: 'Ocorreu um erro ao carregar os empreendimentos',
        mode: 'increment',
        resetDependencies: [filters]
    }, [filters, page, rowsPerPage])


    const nextPage = useCallback (() => {
        setPage(currentPage => currentPage + 1)
    }, [setPage])

    useEffect(() => {
        if(appType || filters) {
            setTotal(0)
            setPage(1)
        }
    }, [appType, filters])

    useEffect(() => {
        if(result) {
            setTotal(result.total)
            onShowOtherResults(result.total === 0)
        }
    }, [result])

    return (
        <div className='real-estates-result box'>
            { result && result.data.length > 0 && (
                <>
                    <ToggleButtonGroup value={viewMode} onChange={(event: any) => setViewMode(event.target.value)}>
                        <ToggleButton value='cards' >Cards</ToggleButton>
                        <ToggleButton value='map'>Mapa</ToggleButton>
                    </ToggleButtonGroup>

                    { (viewMode === 'cards' || viewMode === null) && (
                        <div className='content'>
                            { result.data.map(realEstate => (
                                <MolRealEstateCard key={realEstate.id} data={realEstate} />
                            )) }
                        </div>
                    )}

                    { viewMode === 'map' && (
                        <RealEstatesMap data={result.data} />
                    )}
                </>
            )}
            {loading && <AtomLoader /> }
            {result && result.data.length < total && (
                <AtomButton id='load-more' onClick={nextPage}>
                    Carregar Mais
                </AtomButton> 
            )}

            { result && result.data.length === 0 && (
                <div className='empty'>
                    <AtomPlaceholder />
                    <div className='description'>
                        <Typography variant={`${appType}-h1`} color='primary'>
                            Que pena!
                        </Typography>
                        <Typography variant={`${appType}-body`}>
                            Parece que não existem resultados para sua pesquisa
                        </Typography>
                        <AtomButton onClick={() => navigate('/')}>
                            Voltar para a página inicial
                        </AtomButton>
                    </div>
                </div>
            )}
        </div>
    )

}