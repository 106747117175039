import whiteLogo from 'src/assets/images/white-logo.png'
import whiteShortLogo from 'src/assets/images/white-short-logo.png'
import blueLogo from 'src/assets/images/blue-logo.png'
import colorfulLogo from 'src/assets/images/colorful-logo.png'
import colorfulShortLogo from 'src/assets/images/colorful-short-logo.png'
import { AtomLogoProps, ImageMap } from './types'
import './logo.scss'

const imageMap: ImageMap = {
    blue: blueLogo,
    white: whiteLogo,
    whiteShort: whiteShortLogo,
    colorful: colorfulLogo,
    colorfulShort: colorfulShortLogo
}

export const AtomLogo = ({
    type, 
    ...imgProps
}: AtomLogoProps) => (
    <img
        src={imageMap[type]}
        alt="Logo da Hub House"
        className={`atom-logo ${type} ${imgProps.onClick ? 'clickable' : ''}`}
        {...imgProps}
    />
)