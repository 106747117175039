export const clearObject = (value?: any) => {
    if(!value) return {}

    return Object.entries(value).reduce((accumulator, [key, value]) => {
        if(Array.isArray(value) && value.length > 0) {
            accumulator[key] = value
            return accumulator
        }

        if(!Array.isArray(value) && value !== null && value !== undefined && value !== '') {
            accumulator[key] = value
            return accumulator
        }

        return accumulator
    }, {} as Record<string, any>)
}