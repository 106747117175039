import { useEffect, useState } from 'react'
import { AppType } from 'src/types'

const getDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height
    }
}

export const useBreakpoints = () => {
    const [dimensions, setDimensions] = useState(getDimensions())
    const appType: AppType = dimensions.width < 1250 ? 'mobile' : 'desktop'

    useEffect(() => {
        const handleResize = () => setDimensions(getDimensions())

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    })

    return {
        dimensions,
        appType
    }
}