import { Badge, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { AtomBottomSheet, AtomButton, AtomModal, OrgRealEstateForm } from 'src/app/components'
import { useBreakpoints } from 'src/app/hooks'
import { RealEstateFormData } from 'src/types'
import { clearObject } from 'src/utils'
import './styles.scss'

type Props = {
    filters?: RealEstateFormData
}

export const RealEstatesModalBtn = ({ filters }: Props) => {

    const { appType } = useBreakpoints()

    const [filtersModal, setFiltersModal] = useState<boolean>(false)
    
    const badgeContent = useMemo(() => Object.keys(clearObject(filters)).length, [filters])

    const onOpenFiltersModal = () => setFiltersModal(true)
    const onCloseFiltersModal = () => setFiltersModal(false)

    return (
        <>
            <Badge color='secondary' badgeContent={badgeContent} showZero>
                <AtomButton variant='dark-contained' onClick={onOpenFiltersModal}>
                    <i className='fi fi-rr-settings-sliders'></i>
                    Filtros
                </AtomButton>
            </Badge>

            { appType === 'mobile' && (
                <AtomBottomSheet 
                    isOpen={filtersModal} 
                    onClose={onCloseFiltersModal}
                >
                    <div className='real-estates-modal-btn'>
                        <Typography variant='mobile-h3'>
                            Todos os filtros
                        </Typography>
                        <OrgRealEstateForm
                            initialValue={filters}
                            onSubmit={onCloseFiltersModal}
                        />
                    </div>
                </AtomBottomSheet>
            )}

            { appType === 'desktop' && (
                <AtomModal 
                    flag={filtersModal} 
                    onClose={onCloseFiltersModal} 
                    className='real-estates-modal-btn desktop'
                >
                    <Typography variant='desktop-h3'>
                        Todos os filtros
                    </Typography>
                    <OrgRealEstateForm
                        initialValue={filters}
                        onSubmit={onCloseFiltersModal}
                    />
                </AtomModal>
            )}
        </>
    )
}