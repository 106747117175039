import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import { RealEstate } from 'src/types'
import { MolScrollMenu } from 'src/app/components'
import './real-estate-videos.scss'

type OrgRealEstateVideosProps = {
    data: RealEstate
}

export const OrgRealEstateVideos = ({
    data: {
        videoUrls
    }
}: OrgRealEstateVideosProps) => {
    const { appType } = useBreakpoints()

    if(!videoUrls || videoUrls.length === 0) return null

    return (
        <div className='org-real-estate-videos'>
            <div className='box'>
                <Typography variant={`${appType}-h3`}>
                    Assista o vídeo
                </Typography>
            </div>
            <div className='container'>
                <MolScrollMenu arrowType='white' arrowPlacement='bottom' arrowLabel='vídeo'>
                    {videoUrls && videoUrls.map((video, index) => (
                        <iframe
                            className='video'
                            key={video} 
                            src={video}
                            itemID={index.toString()}
                        />
                    ))}
                </MolScrollMenu>
            </div>
        </div>
    )
}