import { IconButton } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import './footer-socials.scss'

export const MolFooterSocials = () => (
    <div id='mol-footer-socials'>
        <IconButton
            href='https://api.whatsapp.com/send?phone=54999074647&text=Ol%C3%A1!%20Eu%20gostaria%20de%20conhecer%20a%20Hub%20House'
            target='_blank'
        >
            <WhatsAppIcon />
        </IconButton>
        <IconButton
            href="https://www.instagram.com/hubhouse.imb.br/"
            target='_blank'
        >
            <InstagramIcon />
        </IconButton>
        <IconButton
            href=' https://www.facebook.com/hubhouse.imb.br'
            target='_blank'
        >
            <FacebookIcon />
        </IconButton>
        <IconButton
            href='https://www.linkedin.com/company/hubhouse-imb-br'
            target='_blank'
        >
            <LinkedInIcon />
        </IconButton>
    </div>
)