import { Alert, AlertColor, Snackbar, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import './snackbar.scss'
import { useBreakpoints } from 'src/app/hooks'

interface SnackbarProps extends FC {
    show: (value: Pick<Snackbar, 'message' | 'type'>) => void
}

type Snackbar = {
    message: string
    type: AlertColor
    open: boolean
}

const MolSnackbar: SnackbarProps = () => {
    const [ snack, setSnack ] = useState<Snackbar | null>(null)
    const { appType } = useBreakpoints()

    const handleClose = () => setSnack(null)

    useEffect(() => {
        MolSnackbar.show = (value) => setSnack({...value, open: true})
    }, [])

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: appType === 'mobile' ? 'center' : 'right',
            }}
            open={snack?.open}
            onClose={handleClose}
            autoHideDuration={45000}
            className='mol-snackbar'
        >
            <Alert 
                severity={snack?.type} 
                variant="filled"
            >
                <Typography variant={`${appType}-miniText`} color='white'>
                    { snack?.message }
                </Typography>
            </Alert>
        </Snackbar>
    )
}

MolSnackbar.show = () => {
    throw new Error('MolSnackbar não inicializado')
}

export default MolSnackbar