import { ComponentType } from 'react'
import {
    Controller,
    FieldValues,
    useFormContext,
} from 'react-hook-form'
import { ControlledComponentProps, FormInputBaseProps } from './types'

const MolWithController = <P extends AnyObject, F>(
    Component: ComponentType<P & ControlledComponentProps>,
) => {
    const NewComponent = <T extends FieldValues>(
        props: P & FormInputBaseProps<T, F>,
    ) => {
        const { trigger } = useFormContext<T>()

        return (
            <Controller
                name={props.name}
                render={({ field, fieldState, formState }) => {
                    const triggerDependencies = () => {
                        if (!props.dependencies) return

                        props.dependencies
                            .filter(
                                (dependency) => formState.dirtyFields[dependency] === true,
                            )
                            .forEach((dependency) => trigger(dependency))
                    }

                    const input = (
                        <Component
                            {...(props as P)}
                            triggerDependencies={triggerDependencies}
                            field={field}
                            fieldState={fieldState}
                            formState={formState}
                        />
                    )

                    if (props.render) {
                        return props.render({
                            field: field as any,
                            formState: formState as any,
                            input,
                            fieldState,
                            triggerDependencies,
                        })
                    }

                    return input
                }}
            />
        )
    }

    return NewComponent
}



export default MolWithController
