import { FC, PropsWithChildren } from 'react'
import { useAsyncFetch } from '../hooks'
import { FaqService } from 'src/services'
import { FaqsContext } from '../contexts'

export const FaqsProvider: FC<PropsWithChildren> = ({
    children
}) => {
    const { data: faqs, loading, error } = useAsyncFetch({
        callback: () => FaqService.list(),
    }, [])

    return (
        <FaqsContext.Provider value={{faqs, loading, error}}>
            { children }
        </FaqsContext.Provider>
    )
}