import { useEffect, useMemo, useState } from 'react'
import { isFunction } from 'lodash'
import { useImmutableCallback } from './useImmutableCallback'
import { MolSnackbar } from '../components'
import debounce from 'lodash/debounce'
import { AxiosResponse } from 'axios'

type Params<T, Y = T> = {
    callback: () => Promise<T> | undefined
    formatter?: (result: T) => Y
    onError?: (error: unknown) => void
    errorMessage?: string | ((error: unknown) => string | undefined)
    mode?: 'paginate' | 'increment'
    debounceTime?: number
    resetDependencies?: any[]
}

export const useAsyncFetch = <T, Y = T>(
    { callback, formatter, errorMessage, onError, mode = 'paginate', debounceTime, resetDependencies }: Params<T, Y>,
    dependencies: any[],
) => {
    type DataType = Y extends T ? T : Y
    const [data, setData] = useState<DataType | undefined>(undefined)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<unknown>()

    const fetch = useImmutableCallback(debounce(
        async (isAborted: () => boolean = () => false) => {
            try {
                if(mode === 'paginate') setData(undefined)
                setLoading(true)
                setError(undefined)

                const newData = await callback()

                if (!isAborted() && newData) {
                    const result: any = formatter ? formatter(newData) : newData
                    setData((currentData: any) => {
                        if(
                            mode === 'increment' && 
                            ('data' in result && 'total' in result) && 
                            (currentData && 'data' in currentData  && 'total' in currentData)
                        ){
                            return {
                                data: [...currentData.data, ...result.data],
                                total: result.total
                            }
                        }

                        return result
                    })
                } 
            } catch (fetchError) {
                console.error(fetchError)

                if (!isAborted()) setError(fetchError)
            } finally {
                if (!isAborted()) setLoading(false)
            }
        },
        debounceTime || 0
    ))

    useEffect(() => {
        if (!error) return

        if (onError) onError(error)

        const message = isFunction(errorMessage) ? errorMessage(error) : errorMessage

        if (message) {
            MolSnackbar.show({ message, type: 'error' })
        }
    }, [error])

    useEffect(() => {
        let aborted = false        

        fetch(() => aborted)

        return () => {
            aborted = true
        }
    }, dependencies)

    useEffect(() => {
        if(resetDependencies) setData(undefined)
    }, resetDependencies)

    const refetch = useImmutableCallback(() => fetch())

    return useMemo(
        () => ({ loading, data, error, refetch }),
        [loading, data, error, refetch],
    )
}
