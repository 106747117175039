import { useAsyncFetch, useForm, useQuery } from 'src/app/hooks'
import { defaultValues, RealEstateForm, validationSchemas } from './form-control'
import { RealEstateFormData } from 'src/types'
import { roomsQtyOptions, statusOptions } from 'src/utils'
import { AtomButton } from '../../atoms'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { AddressService } from 'src/services'
import { DefaultValues } from 'react-hook-form'
import './real-estate-form.scss'

type OrgRealEstateFormProps = {
    onSubmit?: (value: RealEstateFormData) => void
    initialValue?: DefaultValues<RealEstateFormData>
}

export const OrgRealEstateForm = ({ onSubmit, initialValue }: OrgRealEstateFormProps) => {

    const navigate = useNavigate()
    const { objectToParams } = useQuery()

    const formObject = useForm<RealEstateFormData>({
        defaultValues: {
            ...defaultValues,
            ...(initialValue && { ...initialValue }),
        },
        validationSchema: validationSchemas,
    })

    const city = formObject.form.watch('city')

    const { data: cityOptions } = useAsyncFetch({
        callback: () => AddressService.cities(),
        formatter: addresses => addresses.map(address => ({
            label: address.city,
            value: address.city
        })),
        errorMessage: 'Erro ao buscar as cidades'
    }, [])

    const { data: neighborhoodOptions } = useAsyncFetch({
        callback: () => {
            if(!city) return
            return AddressService.neighborhoods(city)
        },
        formatter: addresses => addresses.map(address => ({
            label: address.neighborhood,
            value: address.neighborhood
        })),
        errorMessage: 'Erro ao buscar os bairros'
    }, [city])

    const submitHandler = () => {
        const formValue = formObject.form.getValues()

        if(onSubmit) onSubmit(formValue)

        navigate({
            pathname: '/real-estate',
            search: objectToParams(formValue)
        })
    }

    useEffect(() => {
        formObject.form.resetField('neighborhood')
    }, [city])

    return (
        <RealEstateForm 
            id='real-estate-form' 
            formObject={formObject}
        >
            <RealEstateForm.Select
                id='city'
                name='city'
                label='Onde você quer investir?'
                placeholder='Selecione a cidade'
                options={cityOptions || []}
            />
            { city && (
                <RealEstateForm.Select
                    id='neighborhood'
                    name='neighborhood'
                    label='Bairro'
                    options={neighborhoodOptions || []}
                />
            )}
            <RealEstateForm.Select
                id='roomsQty'
                name='roomsQty'
                label='Quartos'
                renderValuePosfix='quartos'
                options={roomsQtyOptions}
                multiple
            />
            <RealEstateForm.Select
                id='status'
                name='status'
                label='Status do imóvel'
                options={statusOptions}
                multiple
            />
            <RealEstateForm.TextField 
                id='priceMin'
                name='priceMin'
                label='Preço mínimo'
                type='number'
                startAdornment='R$'
                showHelperMsg={false}
            />
            <RealEstateForm.TextField 
                id='priceMax'
                name='priceMax'
                label='Preço máximo'
                type='number'
                startAdornment='R$'
                showHelperMsg={false}
            />

            <AtomButton type='submit' onClick={() => formObject.form.handleSubmit(submitHandler)()}>
                <i className='fi fi-br-search'></i>
                Buscar
            </AtomButton>
        </RealEstateForm>
    )
}