import { createTheme } from '@mui/material'

const createVariant = (fontSize: string, lineHeight: string, fontWeight: string | number) => ({
    fontSize,
    lineHeight,
    fontWeight,
})

export const customTheme = createTheme({
    typography: {
        fontFamily: 'acumin-pro',
        'desktop-h0': createVariant('42px', '42px', 'bold'),
        'desktop-h1': createVariant('32px', '32px', 'bold'),
        'desktop-h2': createVariant('28px', '28px', 'bold'),
        'desktop-h3': createVariant('22px', '22px', 'bold'),
        'desktop-h5': createVariant('18px', '18px', 600),
        'desktop-h6': createVariant('15px', '15px', 'normal'),
        'desktop-h7': createVariant('14px', '19px', 600),
        'desktop-body': createVariant('16px', '16px', 'normal'),
        'desktop-miniText': createVariant('12px', '12px', 'normal'),
        'desktop-button': createVariant('16px', '16px', 'bold'),
        'desktop-tags': createVariant('12px', '22px', 'lighter'),
        'desktop-caracAptos': createVariant('11px', '11px', 'normal'),
        'mobile-h1': createVariant('32px', '32px', 'bold'),
        'mobile-h2': createVariant('28px', '28px', 'bold'),
        'mobile-h3': createVariant('18px', '18px', 600),
        'mobile-h5': createVariant('16px', '16px', 600),
        'mobile-h6': createVariant('14px', '19px', 600),
        'mobile-body': createVariant('14px', '14px', 'normal'),
        'mobile-miniText': createVariant('12px', '12px', 'normal'),
        'mobile-caracAptos': createVariant('12px', '22px', 600),
        'mobile-tags': createVariant('12px', '22px', 'lighter'),
        'mobile-button': createVariant('16px', '22px', 'bold'),
    },
    palette: {
        mode: 'light',
        primary: {
            light: '#5c6ac4',
            main: '#3445B6',
            dark: '#24307f',
            contrastText: '#fff',
        },
        secondary: {
            light: '#02a56c',
            main: '#03EC9B',
            dark: '#35efaf',
            contrastText: '#000',
        },
        text: {
            primary: '#1E1E1E',
            disabled: '#CBD2E0'
        }
    },
})