import { Album, Faq } from 'src/types'
import { Axios } from 'src/utils'

class FaqService {
    private readonly endpoint = '/faq'

    async list() {
        const { data } = await Axios.get<Faq[]>(this.endpoint)
        return data
    }
}

export default new FaqService()