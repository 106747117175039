import { ConstructionCompany } from 'src/types'
import { Axios, clearObject } from 'src/utils'

type FilterParams = {
    rowsPerPage?: number
    page?: number
    realEstatesCity?: string
}

class ConstructionCompanyService {
    private readonly endpoint = '/construction-company'

    async list(params?: FilterParams) {
        const { data } = await Axios.get<ConstructionCompany[]>(
            this.endpoint,
            { params: clearObject(params) }
        )
        return data
    }

    async find(id: string | number) {
        const { data } = await Axios.get<ConstructionCompany>(
            `${this.endpoint}/${id}`, 
        )
        return data
    }
}

export default new ConstructionCompanyService()