import { TemForm } from 'src/app/components'
import { ConstructionCompaniesFilter } from './types'
import { Yup } from 'src/utils'
import { DefaultValues } from 'react-hook-form'

export const ConstructionCompaniesForm = TemForm<ConstructionCompaniesFilter>()

export const validationSchema = Yup.object().shape({
    addressCity: Yup.string()
})

export const defaultValues: DefaultValues<ConstructionCompaniesFilter> = {
    addressCity: ''
}