import { FC, useContext } from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import './scroll-menu-footer.scss'

type AtomScrollMenuFooterProps = {
    nextLabel?: string
}

export const AtomScrollMenuFooter: FC<AtomScrollMenuFooterProps> = ({ nextLabel }) => {
    const { appType } = useBreakpoints()
    const { scrollPrev, scrollNext, useIsVisible } = useContext(VisibilityContext)
    const isPreviousDisabled = useIsVisible('first', true)
    const isNextDisabled = useIsVisible('last', false)

    return (
        <div className='atom-scroll-menu-footer'>
            <button 
                onClick={() => scrollPrev()} 
                disabled={isPreviousDisabled} 
                className='atom-scroll-menu-footer-arrow'
            >
                <i className='fi fi-rs-angle-left'></i>
                <Typography variant={`${appType}-body`}>
                    Voltar
                </Typography>
            </button>
            <button 
                onClick={() => scrollNext()} 
                disabled={isNextDisabled} 
                className='atom-scroll-menu-footer-arrow'
            >
                <Typography variant={`${appType}-body`}>
                    Próximo{nextLabel ? ` ${nextLabel}` : ''}
                </Typography>
                <i className='fi fi-rs-angle-right'></i>
            </button>
        </div>
    )
}
