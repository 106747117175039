import { Typography, useTheme } from '@mui/material'
import { FC, useMemo } from 'react'
import { useAsyncFetch, useBreakpoints } from 'src/app/hooks'
import { RealEstate } from 'src/types'
import { Line } from 'react-chartjs-2'
import { ChartData } from 'chart.js'
import { max, min, values } from 'lodash'
import { formatCurrency, formatNumber } from 'src/utils'
import { RealEstateService } from 'src/services'
import { AtomLoader } from '../../atoms'
import './real-estate-price-table.scss'

type MolRealEstatePriceTableProps = {
    data: RealEstate
}

export const MolRealEstatePriceTable: FC<MolRealEstatePriceTableProps> = ({ data }) => {
    const { appType } = useBreakpoints()
    const theme = useTheme()

    const { data: prices } = useAsyncFetch({
        callback: () => RealEstateService.findPriceCalculator(data.id),
        errorMessage: 'Erro ao buscar os dados do gráfico'
    }, [data])

    const minPrice = useMemo(() => min(values(prices)) || 0, [prices])

    const maxPrice = useMemo(() => max(values(prices)) || 0, [prices])

    const chartData: ChartData<'line'> | null = useMemo(() => {
        if (!prices) return null

        return {
            labels: [data.address.city, data.address.neighborhood, data.name],
            datasets: [
                {
                    data: [prices.city, prices.neighborhood, prices.realEstate],
                    borderColor: theme.palette.primary.main,
                    pointBorderColor: theme.palette.primary.main,
                    pointBackgroundColor: theme.palette.primary.main,
                    pointRadius: 5,
                },
                {
                    data: [minPrice, minPrice, minPrice],
                    pointRadius: 0,
                    borderDash: [5, 2],
                    borderColor: theme.palette.secondary.main,
                    borderWidth: 2,
                },
                {
                    data: [maxPrice, maxPrice, maxPrice],
                    pointRadius: 0,
                    borderDash: [5, 2],
                    borderColor: theme.palette.secondary.main,
                    borderWidth: 2,
                },
            ],
        }
    }, [prices])

    const options = {
        scales: {
            y: {
                ticks: {
                    color: theme.palette.text.primary,
                    font: {
                        size: 12,
                        family: 'acumin-pro',
                        weight: 500,
                    },
                    callback: (context: any) => {
                        const value = formatNumber(context / 1000, { maximumFractionDigits: 1 })
                        return `${value} mil`
                    }
                },
                grid: {
                    color: theme.palette.text.disabled,
                },
                min: minPrice - (minPrice * 0.1),
                max: maxPrice + (maxPrice * 0.1)
            },
            x: {
                ticks: {
                    color: theme.palette.text.primary,
                    font: {
                        size: 12,
                        family: 'acumin-pro',
                        weight: 500,
                    },
                },
                grid: {
                    color: theme.palette.text.disabled,
                },  
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: theme.palette.secondary.main,
                displayColors: false,
                bodyColor: theme.palette.secondary.contrastText,
                cornerRadius: 4,
                bodyFont: {
                    size: appType === 'desktop' ? 16 : 14,
                    family: 'acumin-pro',
                    weight: 600,
                },
                filter: (tooltip: any) => tooltip.datasetIndex === 0,
                callbacks: {
                    title: () => '',
                    label: ({ raw }: any) => formatCurrency(raw)
                }
            }
        }
    }

    return (
        <div className='mol-real-estate-price-table'>
            <div className='container box'>
                <Typography 
                    variant={`${appType}-h3`} 
                    {...appType === 'desktop' && { textAlign: 'center' }}
                >
                    Compare os preços do m²
                </Typography>

                <div className='content'>
                    { appType === 'desktop' && <div className='offset' /> }
                    { appType === 'desktop' && <div className='offset' /> }
                    <div id='chart'>
                        { chartData
                            ? <Line data={chartData} options={options}/>
                            : <AtomLoader />
                        }
                    </div>
                    { appType === 'desktop' && <div className='offset' /> }
                    <Typography variant={`${appType}-body`} className='description'>
                        Espaço para uma pequena tradução do quadro, a princípio seria uma mensagem meio que padronizada falando o que o quadro significa e assim vai.
                    </Typography>
                    { appType === 'desktop' && <div className='offset' /> }
                </div>
            </div>
        </div>
    )
}