import { IconButton, Typography } from '@mui/material'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { AtomAvatar, AtomButton, AtomCarousel } from 'src/app/components'
import { RealEstateComponentProps } from '../types'
import './carousel.scss'

export const RealEstateCarousel = ({
    data: {
        id,
        name,
        urls,
        constructionCompany,
        address
    }
}: RealEstateComponentProps) => {

    const navigate = useNavigate()

    const goToGallery = useCallback(() => (
        navigate(`/real-estate/${id}/gallery`)
    ), [id])

    return (
        <div className='real-estate-carousel'>
            <div className='overlay'></div>
            <div className='content box'>
                <div className='title'>
                    <AtomAvatar 
                        src={constructionCompany?.profilePictureUrl}
                        size='small'
                    />
                    <Typography variant='mobile-h3' color='white'>
                        {name} - {address.name}
                    </Typography>
                </div>

                <div className='buttons'>
                    <AtomButton variant='tertiary' onClick={goToGallery}>
                        <i className='fi fi-br-picture'></i>
                        Ver Galeria
                    </AtomButton>
                    <div className='spacer'></div>
                    <IconButton className='share'>
                        <i className='fi fi-sr-share'></i>
                    </IconButton>
                </div>

            </div>

            <AtomCarousel>
                {urls && urls.map(url => <img key={url} src={url} />)}
            </AtomCarousel>
        </div>
    )
}