import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import './real-estate-status.scss'
import { forwardRef } from 'react'

type MolRealEstateStatusProps = {
    icon: string
    label: string
    active: boolean
}

export const MolRealEstateStatus = forwardRef<HTMLElement, MolRealEstateStatusProps>(
    ({ icon, label, active }, ref) => {
        const { appType } = useBreakpoints()
    
        return (
            <div className='mol-real-estate-status'>
                <i className={`fi ${icon} ${active ? 'active' : ''}`}></i>
                <Typography 
                    variant={`${appType}-body`} 
                    className={active ? 'active' : ''}
                    ref={ref}
                >
                    { label }
                </Typography>
            </div>
        )
    }
)

MolRealEstateStatus.displayName = 'MolRealEstateStatus'