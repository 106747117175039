import { Checkbox, IconButton, InputAdornment, ListItemText, MenuItem, Select } from '@mui/material'
import { MolFieldEnhancements } from '../field-enhancements/field-enhancements'
import MolWithController from '../with-controller/with-controller'
import { OptionsMap, SelectFieldProps } from './types'
import { useCallback, useMemo } from 'react'
import './select-field.scss'
import _ from 'lodash'
import { getOptionsMap, humanizeValueArray } from 'src/utils'

export const MolSelectField = MolWithController<SelectFieldProps, string>(
    ({
        field,
        fieldState,
        formState: _formState,
        label,
        triggerDependencies,
        showHelperMsg = true,
        options,
        multiple,
        id,
        layout = 'normal',
        labelColor = 'text',
        renderValuePosfix,
        placeholder,
        icon,
        ...props
    }) => {

        const optionsMap: OptionsMap = useMemo(() => getOptionsMap(options), [options])

        const sortOrder = useMemo(() => options.map(item => item.value), [options])

        const renderValue = useCallback(
            (selected: Array<string | number>) => humanizeValueArray(optionsMap, selected, renderValuePosfix),
            [optionsMap, renderValuePosfix]
        )

        const clear = useCallback(() => {
            field.onChange(multiple ? [] : '')
            triggerDependencies()
        }, [])

        return (
            <MolFieldEnhancements
                label={label}
                field={field}
                fieldState={fieldState}
                layout={layout}
                labelColor={labelColor}
                id={id}
            >
                <Select
                    {...props}
                    className={`mol-select-field ${layout}`}
                    disabled={field.disabled}
                    onBlur={field.onBlur}
                    onChange={(event) => {
                        if(multiple) {
                            event.target.value = (event.target.value as any[]).sort((a, b) => (
                                sortOrder.indexOf(a) - sortOrder.indexOf(b)
                            ))
                        }
                        field.onChange(event)
                        triggerDependencies()
                    }}
                    ref={field.ref}
                    value={field.value}
                    id={field.name}
                    error={!!fieldState.error}
                    { ...multiple && {
                        multiple: true,
                        renderValue: renderValue
                    }}
                    multiple={multiple}
                    IconComponent={icon ? icon : () => <i className='fi fi-bs-angle-down'></i>}
                    MenuProps={{
                        style: { zIndex: 5000 }
                    }}
                    {...placeholder && { displayEmpty: true }}
                    variant='outlined'
                    endAdornment={
                        !_.isEmpty(field.value) && !_.isNil(field.value) && (
                            <InputAdornment sx={{ marginRight: '14px' }} position='end'>
                                <IconButton onClick={clear} className='clear-btn'>
                                    <i className='fi fi-rr-cross'></i>
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                >
                    { options && options.map(({ label, value }) => (
                        <MenuItem key={value} value={value} className='mol-select-field-option'>
                            { multiple && (
                                <>
                                    <Checkbox checked={field.value.indexOf(value) > -1} />
                                    <ListItemText primary={label} />
                                </>
                            )}
                            { !multiple && label}
                        </MenuItem>
                    ))}
                </Select>
            </MolFieldEnhancements>
        )
    }
)