import { useCallback, useMemo } from 'react'
import { useBreakpoints } from 'src/app/hooks'
import { AlbumType } from 'src/enums'
import { RealEstate } from 'src/types'
import { MolScrollMenu } from '../../molecules'
import { Typography } from '@mui/material'
import { AtomButton } from '../../atoms'
import { useNavigate } from 'react-router-dom'
import './real-estate-mini-gallery.scss'

type OrgRealEstateMiniGalleryProps = {
    data: RealEstate
}

export const OrgRealEstateMiniGallery = ({
    data: { id, albums, hits }
}: OrgRealEstateMiniGalleryProps) => {
    const { appType } = useBreakpoints()

    const navigate = useNavigate()

    const goToGallery = useCallback(() => (
        navigate(`/real-estate/${id}/gallery`)
    ), [id])

    const album = useMemo(() => {
        if(!albums) return undefined

        return albums.find((item) => item.type === AlbumType.main)
    }, [albums])

    return (
        <div className='org-real-estate-mini-gallery'>
            <div className={`container ${appType === 'desktop' ? 'box' : ''}`}>
                <MolScrollMenu>
                    {album && album.urls.map((url, index) => (
                        <img
                            key={url}
                            itemID={index.toString()}
                            src={url}
                            className='photo'
                        />
                    ))}
                </MolScrollMenu>
                { appType === 'desktop' && (
                    <AtomButton variant='tertiary' onClick={goToGallery}>
                        <i className='fi fi-br-picture'></i>
                        Ver Galeria
                    </AtomButton>
                )}
            </div>
            { appType === 'mobile' && (
                <div className='hits-container box'>
                    <i className='fi fi-rr-eye'></i>
                    <Typography variant={`${appType}-tags`}>
                        {hits} visualizações nos últimos 90 dias
                    </Typography>
                </div>
            )}
        </div>
    )
}