import { useMemo, useState } from 'react'
import { FeatureType } from 'src/enums'
import { Typography } from '@mui/material'
import { useBreakpoints } from 'src/app/hooks'
import { RealEstate } from 'src/types'
import './real-estate-features.scss'

type MolRealEstateFeaturesProps = {
    data: RealEstate
    type: FeatureType
    className?: string
}

export const MolRealEstateFeatures = ({ 
    data: { 
        features 
    },
    className,
    type 
}: MolRealEstateFeaturesProps) => {

    const { appType } = useBreakpoints()

    const [expandedFlag, setExpandedFlag] = useState<boolean>(false)

    const {firstItems, allItems, hasExpansion} = useMemo(() => {
        if(!features) return { firstItems: [], allItems: [], hasExpansion: false }

        const firstItemsLength = appType === 'mobile' ? 5 : 10

        const items = features.filter(item => item.type === type)

        return {
            firstItems: items.slice(0, firstItemsLength),
            allItems: items,
            hasExpansion: items.length > firstItemsLength,
        }
    }, [features])

    const currentArray = useMemo(() => (
        expandedFlag ? allItems : firstItems
    ), [expandedFlag, allItems, firstItems])

    if(allItems.length === 0) return null

    return (
        <div className={`mol-real-estate-features ${className || ''}`}>
            <Typography variant={`${appType}-h3`}>
                { 
                    type === FeatureType.about
                        ? 'Sobre o imóvel'
                        : 'Estrutura do empreendimento' 
                }
            </Typography>

            <div className='feature-container'>
                { currentArray.map(({ id, source, description }) => (
                    <div className='feature' key={id}>
                        <i className={`fi ${source || 'fi-rs-circle-ellipsis'}`}></i>
                        <Typography variant={`${appType}-body`}>
                            { description }
                        </Typography>
                    </div>
                )) }
            </div>

            { hasExpansion && !expandedFlag && (
                <button onClick={() => setExpandedFlag(true)}>
                    <Typography variant={`${appType}-body`}>
                        Carregar mais 
                    </Typography>
                    <i className='fi fi-br-angle-down'></i>
                </button>
            )}
        </div>
    )
}