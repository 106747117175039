import { useBreakpoints } from 'src/app/hooks'
import { RealEstate } from 'src/types'
import { AtomButton, AtomPrice } from '../../atoms'
import { Typography } from '@mui/material'
import { RealEstateStatus } from 'src/enums'
import './real-estate-payment.scss'
import { OrgRealEstateEngage } from '../../organisms'

type MolRealEstatePaymentProps = {
    data: RealEstate
    className?: string
}

export const MolRealEstatePayment = ({
    data: {
        paymentConditions,
        startingValue,
        currentValue,
        status
    },
    className
}: MolRealEstatePaymentProps) => {
    const { appType } = useBreakpoints()

    return (
        <div className={`mol-real-estate-payment ${className || ''}`}>
            <AtomPrice
                format={status === RealEstateStatus.launch ? 'starting_at' : 'buy_it_for'}
                align={appType === 'desktop' ? 'left' : 'center'}
                valueColor='text'
                startingValue={startingValue}
                currentValue={currentValue}
                valueVariant={appType === 'desktop' ? 'desktop-h3' : undefined}
                showUnderline={appType === 'desktop'}
            />
            <div className='content'>
                <div className='title'>
                    <i className='fi fi-rr-usd-circle'></i>
                    <Typography variant={`${appType}-body`}>
                        Condições de pagamento
                    </Typography>
                </div>
                <Typography variant={`${appType}-miniText`} className='payment'>
                    { paymentConditions }
                </Typography>
            </div>
            <OrgRealEstateEngage />
        </div>
    )
}