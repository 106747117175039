import { Card, CardContent, Typography } from '@mui/material'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { BuildingPlan, RealEstate } from 'src/types'
import { useBreakpoints } from 'src/app/hooks'
import './building-plan-card.scss'
// import { BuildingPlan } from 'src/app/pages'

type MolBuildingPlanCardProps = {
    buildingPlan: BuildingPlan
    itemId: number
    realEstateName: string,
}

export const MolBuildingPlanCard = ({ 
    buildingPlan: { 
        id, 
        realEstateId, 
        name, 
        description, 
        planUrl,
        price,
        paymentConditions,
    },
    realEstateName,
    
}: MolBuildingPlanCardProps) => {

    const navigate = useNavigate()
    const { appType } = useBreakpoints()

    const goToPage = useCallback(() => (
        navigate(`/real-estate/${realEstateId}/building-plan/${id}`, {
            state: { realEstateName, paymentConditions, price, planUrl, name }
        })
    ), [realEstateId, id])

    return (
        <Card className='building-plan-card'>
            <CardContent className='content'>
                <img src={planUrl} className='plan' />
                <Typography variant={`${appType}-body`} className='name'>
                    { name }
                </Typography>
                <Typography variant={`${appType}-miniText`} className='description'>
                    { description }
                </Typography>
                <button onClick={goToPage} className='cta'>
                    <Typography variant={`${appType}-body`}>
                        Visualizar planta
                    </Typography>
                </button>
            </CardContent>
        </Card>
    )
}