import { IconButton } from '@mui/material'
import { OrgSearch } from '../../organisms'
import './header-search.scss'

export const MolHeaderSearch = () => {
    return (
        <div id='mol-header-search'>
            <IconButton onClick={() => OrgSearch.open()}>
                <i className='fi fi-br-search'></i>
            </IconButton>
        </div>
    )
}