import { AtomUnderlinedText, OrgBestOpportunities } from 'src/app/components'
import { useBreakpoints } from 'src/app/hooks'
import { Typography } from '@mui/material'
import { RealEstatesResult } from './result'
import { useState } from 'react'
import { RealEstatesOtherResults } from './other-results'
import { RealEstatesGetInfo } from './get-info'
import { RealEstatesFilters } from './filters'
import { RealEstateFormData } from 'src/types'
import './styles.scss'

export const RealEstates = () => {

    const { appType } = useBreakpoints()

    const [filters, setFilters] = useState<RealEstateFormData | undefined>(undefined)
    const [showOtherResults, setShowOtherResults] = useState<boolean>(false)
 
    return (
        <div className='real-estates-page'>
            <div className='head'>
                <div className='title box'>
                    <AtomUnderlinedText showUnderline={appType === 'desktop'}>
                        <Typography variant={`${appType}-h3`}>
                            Resultados da busca
                        </Typography>
                    </AtomUnderlinedText>
                </div>

                <RealEstatesFilters onSubmit={val => setFilters(val)} />
            </div>


            <RealEstatesResult 
                filters={filters}
                onShowOtherResults={val => setShowOtherResults(val)}
            />

            {showOtherResults && <RealEstatesOtherResults /> }

            <OrgBestOpportunities desktopBgColor='gray'/>

            <RealEstatesGetInfo />
        </div>
    )
}