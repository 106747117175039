import {
    AtomButton,
    AtomDivider,
    AtomPlaceholder,
    AtomUnderlinedText,
    MolGetInfo,
    MolRealEstateCard,
    OrgConstructionCompanyInfo,
    OrgConstructionCompanyOthers
} from 'src/app/components'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsyncFetch, useBreakpoints, useForm } from 'src/app/hooks'
import { ConstructionCompanyService, RealEstateService } from 'src/services'
import { Typography } from '@mui/material'
import { ConstructionCompanyForm, defaultValues, validationSchema } from './form-controller'
import { ConstructionCompanyFilter } from './types'
import { useEffect, useState } from 'react'
import { statusOptions } from 'src/utils'
import { ConstructionCompanyBanner } from './components/banner/banner'
import { ConstructionCompanyLoading } from './components'
import './styles.scss'

export const ConstructionCompany = () => {
    const { id } = useParams()
    const { appType } = useBreakpoints()
    const navigate = useNavigate()

    const formObject = useForm<ConstructionCompanyFilter>({
        validationSchema: validationSchema,
        defaultValues: defaultValues
    })

    const status = formObject.form.watch('status')

    const { data: constructionCompany, loading } = useAsyncFetch({
        callback: () => {
            if(!id) return
            return ConstructionCompanyService.find(id)
        },
        errorMessage: 'Erro ao buscar o empreendimento'
    }, [id])

    const { data: realEstates } = useAsyncFetch({
        callback: async () => {
            if(!id) return
            const response = await RealEstateService.list({
                constructionCompanyId: Number(id),
                status: status,
                rowsPerPage: 50
            })
    
            return response
        },
        errorMessage: 'Erro ao buscar os empreendimentos',
    }, [id, status])

    const [limit, setLimit] = useState(appType === 'desktop' ? 6 : 4)


    useEffect(() => {
        setLimit(appType === 'desktop' ? 6 : 4)
    }, [appType])

    const handleShowAllClick = () => {
        setLimit(50)
    }

    return (
        <div className='construction-company-page'>
            { loading && <ConstructionCompanyLoading/> }
            { constructionCompany && <>
                <ConstructionCompanyBanner data={constructionCompany} />
                <AtomDivider className='divider box' />

                <div className='list box'>            
                    <ConstructionCompanyForm formObject={formObject} id='form'>
                        <ConstructionCompanyForm.Select
                            className='box'
                            name='status'
                            options={ statusOptions || []}
                            label='Filtre pelo status do empreendimento'
                            multiple
                        />
                    </ConstructionCompanyForm>

                    {realEstates && realEstates.data.length > 0 && (
                        <div>

                            <AtomUnderlinedText showUnderline={appType === 'desktop'}>
                                <Typography variant={`${appType}-h3`}>
                                    Empreendimentos
                                </Typography>
                            </AtomUnderlinedText>

                            <div className='buildings'>
                                {realEstates.data.slice(0, limit).map((realEstate, index) => (
                                    <div key={index} className='real-estate'>
                                        <MolRealEstateCard data={realEstate} />
                                    </div>
                                ))}
                            </div>
                            {limit < 50 && 
                                <AtomButton
                                    id='show-all'
                                    variant='primary'
                                    onClick={handleShowAllClick}
                                >
                                    Mostrar todos empreendimentos
                                </AtomButton>
                            }
                        </div>
                    )}
                    

                    {realEstates && realEstates.data.length === 0 && (
                        <div className='empty'>
                            <AtomPlaceholder />
                            <div className='description'>
                                <Typography variant={`${appType}-h1`} color='primary'>
                                    Que pena!
                                </Typography>
                                <Typography variant={`${appType}-body`}>
                                    Parece que não existem resultados para sua pesquisa
                                </Typography>
                            </div>
                        </div>
                    )}

                </div>

                <AtomDivider className='divider box' />

                <div className='about box'>
                    <OrgConstructionCompanyInfo 
                        data={constructionCompany} 
                        showMap
                        showAvatar={false}
                        btnRedirect='reviews'
                    />
                </div>
                
                <div className='more-oportunities'>
                    {realEstates && realEstates.data.length > 0 && <OrgConstructionCompanyOthers data={realEstates?.data[0]} />}
                </div>

                <div className='get-info'>
                    <MolGetInfo />
                </div>
            </> }
        </div>
    )
}