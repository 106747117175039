import { Typography, useTheme } from '@mui/material'
import { AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps'
import { useMemo } from 'react'
import { useBreakpoints } from 'src/app/hooks'
import { RealEstate } from 'src/types'
import { AtomLogo } from '../../atoms'
import { env } from 'src/utils'
import { MolRealEstateAddress } from '../real-estate-address/real-estate-address'
import './real-estate-map.scss'

type MolRealEstateMapProps = {
    data: RealEstate
}

export const MolRealEstateMap = ({ data }: MolRealEstateMapProps) => {

    const { appType } = useBreakpoints()
    const theme = useTheme()

    const position: google.maps.LatLngLiteral = useMemo(() => ({
        lat: data.address.location?.coordinates[0] || 0,
        lng: data.address.location?.coordinates[1] || 0,
    }), [data])

    return (
        <div className='mol-real-estate-map box' id='map'>
            <Typography variant={`${appType}-h3`}>
                Localização do {data.name}
            </Typography>
            { appType === 'desktop' && (
                <MolRealEstateAddress data={data} showLocationLink={false} />
            )}
            <Map 
                mapId={env.googleCloud.mapsMapId} 
                defaultCenter={position} 
                defaultZoom={appType === 'mobile' ? 15 : 16} 
                className='map-container'
                disableDefaultUI={true}
            >
                <AdvancedMarker position={position} title={data.name}>
                    <Pin 
                        background={theme.palette.primary.main} 
                        borderColor={theme.palette.primary.main} 
                        scale={1.4}
                    >
                        <AtomLogo type='whiteShort' />
                    </Pin>
                </AdvancedMarker>
            </Map>
        </div>
    )
}